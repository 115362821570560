import { createContext, useState, ReactNode, SetStateAction, Dispatch } from "react";

export type Severity = 'success' | 'info' | 'warning' | 'error';

type MetaContextType = {
  handleAsyncOperation: (operation: () => Promise<any>) => Promise<any>,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  alertMessage: string | null,
  setAlertMessage: Dispatch<SetStateAction<string | null>>,
  alertSeverity: Severity,
  setAlertSeverity: Dispatch<SetStateAction<Severity>>,
  selectedRapportIds: string[],
  setSelectedRapportIds: Dispatch<SetStateAction<string[]>>,
};

export const MetaContext = createContext<MetaContextType>({
  handleAsyncOperation: async () => {},
  isLoading: false,
  setIsLoading: () => {},
  alertMessage: null,
  setAlertMessage: () => {},
  alertSeverity: 'success',
  setAlertSeverity: () => {},
  selectedRapportIds: [],
  setSelectedRapportIds: () => {},
});

type MetaContextProviderProps = {
  children: ReactNode;
};

const MetaContextProvider = (props: MetaContextProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRapportIds, setSelectedRapportIds] = useState<string[]>([]);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertSeverity, setAlertSeverity] = useState<Severity>('success');

  const handleAsyncOperation = async (operation: () => Promise<any>): Promise<any> => {
    try {
      setIsLoading(true);
      return await operation();
    } catch (error) {
      setAlertMessage("Ein Fehler ist aufgetreten.");
      setAlertSeverity("error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MetaContext.Provider
      value={{
        handleAsyncOperation,
        isLoading,
        setIsLoading,
        alertMessage,
        setAlertMessage,
        alertSeverity,
        setAlertSeverity,
        selectedRapportIds,
        setSelectedRapportIds,
      }}
    >
      {props.children}
    </MetaContext.Provider>
  );
}

export default MetaContextProvider;
