import { ChangeEvent, useContext, useEffect } from "react";
import { Measurements } from "../../models/measurements";
import { Stack, TextField } from "@mui/material";
import { SchochStack } from "../../styles/styles";
import { RapportContext } from "../Provider/RapportContextProvider";
import { ItemProps } from "../Dialogs/ItemDialog";
import RapportService from "../../services/rapport-service";

const MeasurementsItem = (props: ItemProps) => {
  const rapportContext = useContext(RapportContext);

  const handleInputChange = (key: keyof Measurements) => (evt: ChangeEvent<HTMLInputElement>) => {
    rapportContext.updateRapport({...rapportContext.rapport, measurements: { ...rapportContext.rapport.measurements, [key]: evt.target.value } });
  };

  useEffect(() => {
    const isFilled = RapportService.isMeasurementFilled(rapportContext.rapport);
    props.updateIsFilled(isFilled);
  }, [rapportContext.rapport.measurements]);

  return (
    <Stack sx={{ gap: 2, marginTop: 2 }}>
      <SchochStack>
        <TextField
          label="FI Auslösestärke"
          value={rapportContext.rapport.measurements.fi}
          onChange={handleInputChange('fi')}
        />
        <TextField
          label="Sichtkontrolle nach NIN"
          value={rapportContext.rapport.measurements.nin}
          onChange={handleInputChange('nin')}
        />
        <TextField
          label="TN-S"
          value={rapportContext.rapport.measurements.tnS}
          onChange={handleInputChange('tnS')}
        />
        <TextField
          label="TN-C"
          value={rapportContext.rapport.measurements.tnC}
          onChange={handleInputChange('tnC')}
        />
        <TextField
          label="TN-C-S"
          value={rapportContext.rapport.measurements.tnCS}
          onChange={handleInputChange('tnCS')}
        />
        <TextField
          label="Zähler-Nr."
          value={rapportContext.rapport.measurements.counter}
          onChange={handleInputChange('counter')}
        />
        <TextField
          label="In (A), PE geprüft"
          value={rapportContext.rapport.measurements.inA}
          onChange={handleInputChange('inA')}
        />
        <TextField
          label="R iso"
          value={rapportContext.rapport.measurements.rIso}
          onChange={handleInputChange('rIso')}
        />
        <TextField
          label="IK (A)"
          value={rapportContext.rapport.measurements.ikA}
          onChange={handleInputChange('ikA')}
        />
        <TextField
          label="M Ohm"
          value={rapportContext.rapport.measurements.mOhm}
          onChange={handleInputChange('mOhm')}
        />
      </SchochStack>
    </Stack>
  );
};


export default MeasurementsItem;

