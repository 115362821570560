import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Protected from "./components/AuthRoute/Protected";
import Superuser from "./components/AuthRoute/Superuser";
import CreatePage from "./pages/CreatePage";
import EditPage from "./pages/EditPage";
import FirmPage from "./pages/FirmPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import OverviewPage from "./pages/OverviewPage";
import ProfilePage from "./pages/ProfilePage";
import RapportListPage from "./pages/RapportListPage";
import RapportPage from "./pages/RapportPage";
import RegistrationPage from "./pages/RegistrationPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SecretPage from "./pages/SecretPage";
import SettingsPage from "./pages/SettingsPage";
import SupportPage from "./pages/SupportPage";
import WorktimePage from "./pages/WorktimePage";

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <HomePage /> },
      { path: '*', element: <NotFoundPage /> },
      { path: '/registration', element: <RegistrationPage /> },
      { path: '/login', element: <LoginPage /> },
      { path: '/reset-password', element: <ResetPasswordPage /> },
      { path: '/firm', element: <Protected component={FirmPage} /> },
      { path: '/rapport', element: <Protected component={CreatePage} /> },
      { path: '/rapport/:id', element: <Protected component={RapportPage} /> },
      { path: '/rapport/:id/edit', element: <Protected component={EditPage} /> },
      { path: '/overview', element: <Protected component={OverviewPage} /> },
      { path: '/profile', element: <Protected component={ProfilePage} /> },
      { path: '/list', element: <Protected component={RapportListPage} /> },
      { path: '/time', element: <Protected component={WorktimePage} /> },
      { path: '/support', element: <Protected component={SupportPage} /> },
      { path: '/settings', element: <Protected component={SettingsPage} /> },
      { path: '/secret', element: <Superuser component={SecretPage} /> },
    ],
  },
]);

export default router;