import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { orange, green, red } from "@mui/material/colors";
import { createContext, ReactNode, useEffect, useState } from "react";
import UserService from "../../services/user-service";

declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter?: string;
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }
};

type ThemeContextType = {
  isDarkTheme: boolean;
  toggleTheme: () => void;
  updateTheme: (theme: any) => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  isDarkTheme: false,
  toggleTheme: () => {},
  updateTheme: () => {},
});

type ThemeContextProviderProps = {
  children: ReactNode;
};

const ThemeContextProvider = (props: ThemeContextProviderProps) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [themeValues, setThemeValues] = useState({
    primaryMain: "#FF9800",
    primaryLight: "#FFB74D",
    primaryDark: "#F57C00",
    primaryDarker: "#E65100",
    primaryLighter: "#FFE0B2",
    successMain: "#4CAF50",
    errorMain: "#F44336",
    backgroundDefault: "#333333",
    backgroundPaper: "#444444",
    textPrimary: "#EEEEEE",
    textSecondary: "#DDDDDD",
    grey50: "#555555",
    grey100: "#666666",
    grey200: "#777777",
    grey400: "#888888",
    grey800: "#999999",
  });
  
  const toggleTheme = () => {
    setIsDarkTheme(prev => {
      const newTheme = !prev;
      UserService.setDarkMode(newTheme);
      return newTheme;
    });
  };

  const updateTheme = (themeValues: any) => {
    setThemeValues(themeValues);
  };

  const lightTheme = createTheme({
    palette: {
      primary: {
        main: orange[500],
        lighter: orange[200],
        light: orange[300],
        dark: orange[700],
        darker: orange[900],
      },
      success: {
        main: green[500],
        lighter: green[200],
        darker: green[900],
      },
      error: {
        main: red[500],
        lighter: red[200],
        darker: red[900],
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: themeValues.primaryMain,
        lighter: themeValues.primaryLighter,
        light: themeValues.primaryLight,
        dark: themeValues.primaryDark,
        darker: themeValues.primaryDarker,
      },
      success: {
        main: themeValues.successMain,
        lighter: green[200],
        darker: green[900],
      },
      error: {
        main: themeValues.errorMain,
        lighter: red[200],
        darker: red[900],
      },
      background: {
        default: themeValues.backgroundDefault,
        paper: themeValues.backgroundPaper,
      },
      text: {
        primary: themeValues.textPrimary,
        secondary: themeValues.textSecondary,
      },
      grey: {
        50: themeValues.grey50,
        100: themeValues.grey100,
        200: themeValues.grey200,
        400: themeValues.grey400,
        800: themeValues.grey800,
      },
    },
  });

  useEffect(() => {
    const currentBackground = isDarkTheme ? darkTheme.palette.background.default : lightTheme.palette.background.default;
    document.body.style.backgroundColor = currentBackground;
    setIsDarkTheme(UserService.getDarkMode());
  }, [lightTheme, darkTheme]);
  
  return (
    <ThemeContext.Provider value={{
      isDarkTheme,
      toggleTheme,
      updateTheme,
    }}>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        {props.children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;