import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Tapbar from './components/Tapbar';
import Loading from './components/Loading';
import AlertSnackbar from './components/AlertSnackbar';
import { Box, Theme, useMediaQuery } from '@mui/material';
import PWAInstallPrompt from './components/PWAInstallPrompt';
import Sidebar from './components/Sidebar';
import { AuthContext } from './components/Provider/AuthContextProvider';

const App: React.FC = () => {
  const authContext = useContext(AuthContext);
  const isXsOrSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, height: '100%', width: '100%', }}>
      {!isXsOrSm && (<Sidebar />)}
      <Box sx={{ flex: 1, overflowY: 'auto', paddingBottom: { xs: 8, lg: 0 } }} >
        <Outlet />
        {authContext.isAuthenticated && isXsOrSm && <Tapbar />}
        <AlertSnackbar />
        {isXsOrSm && <PWAInstallPrompt />}
      </Box>
      <Loading />
    </Box>
  );
};

export default App;
