import { Dialog, DialogTitle, DialogContent, Box, DialogActions, Button, TextField, Autocomplete, Stack, Typography, SelectChangeEvent, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Worktime, defaultWorktime } from "../../models/worktime";
import { MeContext } from "../Provider/MeContextProvider";
import { User } from "../../models/user";
import WorktimeType, { worktimeOptions } from "../../models/worktime-type";

type WorktimeDialogProps = {
  worktime: Worktime | null;
  index: number;
  lockedName: boolean;
  isOpen: boolean;
  onSave: (worktime: Worktime, index: number) => void;
  onClose: () => void;
};

const WorktimeDialog = (props: WorktimeDialogProps) => {
  const meContext = useContext(MeContext);
  const [worktime, setWorktime] = useState<Worktime>(defaultWorktime);
  const [displayError, setDisplayError] = useState(false);

  const updateWorktime = (key: keyof Worktime) => (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value;
    if (key === 'date') {
      setWorktime(prev => ({ ...prev, ['date']: !!evt.target.value ? new Date(newValue) : new Date(0) }));
    } else {
      setWorktime(prev => ({ ...prev, [key]: newValue}));
    }
  };

  const handleTextChange = (newValue: string) => {
    const user = meContext.firmUsers?.find((user) => user.username.toLowerCase() === newValue.toLowerCase());
    setWorktime(prev => ({ ...prev, type: 'work', user: user ?? null, username: newValue }));
  };

  const handleChange = (newValue: string | User | null) => {
    if (typeof newValue === 'string') {
      handleTextChange(newValue);
    } else if (newValue === null) {
      setWorktime(prev => ({ ...prev, type: 'work', user: null, username: "" }));
    } else {
      setWorktime(prev => ({ ...prev, type: 'work', user: newValue, username: newValue.username }));
    }
  };

  const handleTypeChange = (event: SelectChangeEvent<WorktimeType>) => {
    
    const newType = event.target.value as WorktimeType;
    setWorktime(prev => ({ ...prev, type: newType }));
  };

  const filteredUsers = (meContext.firmUsers === null) ? [] : meContext.firmUsers.filter((user) =>
    user.username.toLowerCase().includes(worktime.username.toLowerCase())
  );

  const reset = () => {
    setWorktime({...defaultWorktime, user: meContext.currentUser, username: meContext.currentUser!.username});
    setDisplayError(false);
  };

  const save = () => {
    if (worktime.date === null) {
      setDisplayError(true);
      return;
    }
    
    props.onSave(worktime, props.index);
  };

  useEffect(() => {
    if (props.worktime !== null) {
      setWorktime(props.worktime);
    }
  }, [props.worktime]);

  return (
  <Dialog
    open={props.isOpen}
    onClose={props.onClose}
  >
    <DialogTitle>Arbeitszeit</DialogTitle>
    <DialogContent>
      <Stack flexWrap='wrap' gap={2}>
        {props.lockedName ?
          <Box>
            <Typography sx={{ fontWeight: 'bold', paddingLeft: 2, }}>{worktime.username}</Typography>
          </Box> :
          <Box display='flex' flexDirection='row' alignItems='center' paddingTop={1}>
            <Autocomplete
              size="small"
              freeSolo
              sx={{ flex: 1 }}
              value={worktime.username}
              noOptionsText='Keine Treffer'
              options={filteredUsers}
              getOptionLabel={(user) => (typeof user === 'string' ? user : user.username)}
              renderInput={(params) => <TextField {...params} variant="outlined" label='Name' onChange={(newValue) => handleTextChange(newValue.target.value)} />}
              onChange={(_, newValue) => handleChange(newValue)}
            />
          </Box>
        }
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, }}>
          <Typography sx={{ paddingLeft: 2, width: 90}}>{worktime.date?.toLocaleDateString('de-DE', { weekday: 'long' })},</Typography>
          <TextField
            value={worktime.date?.toLocaleDateString('sv-SE') ?? ''}
            type='date'
            onChange={updateWorktime("date")}
            size='small'
            sx={{ flex: 1, }}
            error={displayError}
            helperText={displayError ? 'Datum wird benötigt' : ''}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, }}>
        <TextField
          value={worktime.price}
          label='Preis'
          type="number"
          size="small"
          onChange={updateWorktime("price")}
        />
        <TextField
          value={worktime.timeInHours}
          label='Arbeitszeit'
          type="number"
          size="small"
          onChange={updateWorktime("timeInHours")}
        />
        </Box>
        {props.lockedName &&
          <FormControl>
            <InputLabel>Erfassungs-Typ</InputLabel>
            <Select
              value={worktime.type}
              size="small"
              onChange={handleTypeChange}
              label="Erfassungs-Typ"
            >
              {worktimeOptions.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        <TextField
          value={worktime.description}
          label='Beschreibung'
          type="text"
          size="small"
          onChange={updateWorktime("description")}
        />
      </Stack>
    </DialogContent>
    <DialogActions sx={{paddingBottom: 2, paddingRight: 2}}>
      <Button onClick={save}>Speichern</Button>
    </DialogActions>
  </Dialog>
  );
}

export default WorktimeDialog;