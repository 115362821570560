import { List, ListItemAvatar, ListItemText, IconButton, Avatar, ListItemButton, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EntryOverview from './Dialogs/EntryOverview';
import { useContext, useState } from 'react';
import { RapportContext } from './Provider/RapportContextProvider';
import DynamicIcon from './DynamicIcon';
import ItemEntry from '../models/item-entry';

type RapportEntryListProps = {
  entries: ItemEntry[];
  isEditable: boolean;
};

const RapportEntryList = (props: RapportEntryListProps) => {
  const rapportContext = useContext(RapportContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDeleteItem = (event: React.MouseEvent<HTMLButtonElement>, item: ItemEntry) => {
    event.stopPropagation();
    const index = rapportContext.rapport.entries.findIndex(entry => entry.id === item.id);
  
    if (index !== -1) {
      const updatedEntries = [...rapportContext.rapport.entries];
      updatedEntries[index] = { ...updatedEntries[index], isActive: false };
  
      rapportContext.updateRapport({ ...rapportContext.rapport, entries: updatedEntries });
    }
  };

  return (
    <Stack>
      <List>
        {props.isEditable &&
        <ListItemButton onClick={() => setDialogOpen(true)}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Neues Element hinzufügen" />
        </ListItemButton>}
        {rapportContext.rapport.entries
          .filter(item => item.isActive === true)
          .map((item) => (
        <ListItemButton sx={{ gap: 2 }} key={item.id} onClick={() => rapportContext.setSelectedItem(item)}>
          <IconButton onClick={(event) => handleDeleteItem(event, item)}>
            <DynamicIcon iconName='Delete' />
          </IconButton>
          <ListItemAvatar>
            <Avatar
              sx={{ backgroundColor: theme => theme.palette.primary.main }}
              variant='rounded'
            >
              <DynamicIcon iconName={item.iconName} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.name} />
        </ListItemButton>
        ))}
      </List>
      <EntryOverview open={dialogOpen} closeDialog={() => setDialogOpen(false)} />
    </Stack>
  );
};

export default RapportEntryList;
