import { Box, Button, IconButton, Paper, PopoverPosition, Stack, Tooltip, Typography } from "@mui/material";
import { Rapport } from "../models/rapport";
import { useNavigate } from "react-router-dom";
import { Row } from "../styles/styles";
import { useState } from "react";
import DynamicIcon from "./DynamicIcon";
import TotalDialog from "./Dialogs/TotalDialog";
import { PriceTotal } from "../models/price-total";
import RapportViewAddress from "./RapportViewAddress";

type RapportViewShortProps = {
  rapport: Rapport;
  priceTotal: PriceTotal;
};

const RapportViewShort = (props: RapportViewShortProps) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<PopoverPosition | null>(null);

  const handleTotalClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl({ top: event.clientY - 6, left: event.clientX + 2 });
  };

  const calculateRemainingTime = (futureDate: Date): string => {
    const now = new Date();
    const current = now.getTime();
    const target = new Date(futureDate).getTime();
    const difference = target - current;

    const isToday = futureDate.toDateString() === now.toDateString();
  
    if (difference <= 0) return "Termin hat begonnen";
  
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if (!isToday) {
      return props.rapport.dueDate?.toLocaleDateString('de-DE', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' }) ?? '';
    }

    if (hours !== 0) {
      return `Heute, um ${props.rapport.dueDate?.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`;
    }

    if (minutes <= 1) {
      return 'Jetzt';
    }

    return `In ${minutes} Minuten`;
  };

  return (
    <Paper elevation={3}>
      <Stack sx={{ padding: 4, gap: 4, }}>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center', }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Nächster Rapport</Typography>
          <Typography fontWeight={'bold'}>
            {(props.rapport.dueDate !== null ? calculateRemainingTime(props.rapport.dueDate) : '(Kein Datum)')}
          </Typography>
        </Box>
        {(props.rapport.description.trim() !== "" && props.rapport.betreff.trim() !== "") && <Stack gap={2}>
          <Row alignItems='center' gap={2}>
            <DynamicIcon iconName='Description' />
            <Typography variant='h6'>{props.rapport.betreff.trim() !== "" ? props.rapport.betreff : "(Kein Betreff)"}</Typography>
          </Row>
          <Typography variant='subtitle1'>{props.rapport.description.trim() !== "" ? props.rapport.description : "(Keine Beschreibung)"}</Typography>
        </Stack>}

        <RapportViewAddress rapport={props.rapport} />

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', gap: 2 }}>
          {props.rapport.showPrices && <Row sx={{ gap: 2, alignItems: 'center' }}>
            <Tooltip title="Berechnung anzeigen">
              <IconButton onClick={handleTotalClick}>
                <DynamicIcon iconName="Sell" />  
              </IconButton>
            </Tooltip>
            <Typography>{props.priceTotal.total.toFixed(2)} CHF</Typography>
          </Row>}
          <Row gap={2}>
            <Button variant="contained" sx={{ width: 100, bottom: 0 }} onClick={() => navigate(`/rapport/${props.rapport.id}`)}>Anzeigen</Button>
            <Button variant="contained" sx={{ width: 100, bottom: 0 }} onClick={() => navigate(`/rapport/${props.rapport.id}/edit`)}>Bearbeiten</Button>
          </Row>
        </Box>
      </Stack>
      <TotalDialog priceTotal={props.priceTotal} isOpen={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl} />
    </Paper>
  );
};

export default RapportViewShort;