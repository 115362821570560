import Dialog from '@mui/material/Dialog';
import { SchochStickyRow, SlideUpTransition } from '../../styles/styles';
import { Box, InputAdornment, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import DialogTitlebar from '../DialogTitlebar';
import AddressService from '../../services/address-service';
import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { MetaContext } from '../Provider/MetaContextProvider';
import { Address } from '../../models/address';
import AddressBookTable from '../AddressBookTable';
import debounce from 'lodash/debounce';
import { MeContext } from '../Provider/MeContextProvider';
import AddressDialog from './AddressDialog';
import SchochButton from '../Buttons/SchochButton';
import { FirmContext } from '../Provider/FirmContextProvider';
import { ConfirmDialog } from './Dialog';

type AddressBookDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  pagination: boolean;
};

const AddressBookDialog = (props: AddressBookDialogProps) => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const firmContext = useContext(FirmContext);
  const [isOpen, setIsOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleSyncButtonClick = async () => {
    if (firmContext.addressBook.length > 0) {
      setConfirmDialogOpen(true);
    } else {
      await syncAddressBook();
    }
  };

  const syncAddressBook = async () => {
    const addressBook = await context.handleAsyncOperation(
      AddressService.syncAddressBookQuarantine
    );

    firmContext.setAddressBook(prev => ([...prev, ...addressBook]));

    context.setAlertSeverity("success");
    context.setAlertMessage("Das Adressbuch wurde erfolgreich synchronisiert.");

    await firmContext.fetchAddressBook(1, 100);
  };

  const handleSearchChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value;
    debouncedSearch(newValue);
  };

  const debouncedSearch = useCallback(debounce(async (searchTerm?: string) => {
    await firmContext.fetchAddressBook(1, 100, searchTerm);
  }, 400), []);

  const saveAddress = async (address: Address) => {
    const id = await context.handleAsyncOperation(
      () => AddressService.addAddress(address)
    );

    const newAddress = { ...address, id: id };

    if (id !== null) {
      context.setAlertMessage('Die Adresse wurde erfolgreich gespeichert.');
      context.setAlertSeverity('success');
      firmContext.setAddressBook(prev => ([...prev, newAddress]));
    } else {
      context.setAlertMessage("Ein Fehler ist beim Hinzufügen ins Adressbuch aufgetreten.");
      context.setAlertSeverity("error");
    }

    setIsOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={SlideUpTransition}
    >
      <DialogTitlebar title='Adressbuch' onClose={props.onClose}/>
      <Stack sx={{ overflowY: 'auto', gap: 2, paddingTop: '0 !important' , padding: { sm: 2 } }}>
        <SchochStickyRow sx={{ alignItems: 'center', justifyContent: 'space-between', padding: 1, paddingLeft: 2, }}>
          <TextField
            variant='standard'
            label='Adressen durchsuchen'
            onChange={handleSearchChange}
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            }}
          />
          {meContext.currentUser?.isAdmin && <Box display='flex' flexDirection='row' gap={2}>
            <SchochButton
              icon={<AddIcon />}
              text='Neu'
              disabled={!!!meContext.currentFirm}
              onClick={() => setIsOpen(true)}
            />
            <SchochButton
              icon={<SyncIcon />}
              text='Sync..'
              disabled={!!!meContext.currentFirm}
              onClick={handleSyncButtonClick}
            />
          </Box>}
        </SchochStickyRow>
        <AddressBookTable isDetailed={props.pagination} />
        <AddressDialog address={null} isOpen={isOpen} onSave={saveAddress} onClose={() => setIsOpen(false)} />
        <ConfirmDialog
          title="Mit SchochAuftrag synchronisieren?"
          description="Wollen Sie mit der Synchronisation fortfahren? Falls Sie hier Änderungen am Adressbuch vorgenommen haben, werden diese überschrieben und diejenigen von SchochAuftrag übernommen."
          isOpen={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          onConfirm={() => { syncAddressBook(); setConfirmDialogOpen(false); }}
        />
      </Stack>
    </Dialog>
  );
};

export default AddressBookDialog;