import { JobDto } from "../dto/job-dto";
import { Job } from "../models/job";

// Todo: Arguable whether this should be in job-service, since technically helper classes should only be accessed by service classes.

const jobLabels = [
  "Miele Waschmaschine",
  "Schemaunterlagen ergänzt",
  "Beschriftung / Bezeichnung",
  "Material bestellen / bereitstellen",
  "Störungsquelle gesucht",
  "Defekte Bauteile gesucht",
  "Neues Bauteil eingebaut",
  "Thermorelais eingebaut",
  "Drehrichtungskontrolle",
  "Funktionskontrolle",
  "Isolationsmessung",
  "Inbetriebnahme",
  "Einzug",
  "Instruktion",
  "Fremdmaterial",
  "Messung",
];

const jobFieldMapping: { [label: string]: keyof Job } = {
  "Miele Waschmaschine": "mieleWaschmaschine",
  "Schemaunterlagen ergänzt": "schemaunterlagenErgänzt",
  "Beschriftung / Bezeichnung": "beschriftung",
  "Material bestellen / bereitstellen": "materialBestellen",
  "Störungsquelle gesucht": "stoerungsquelleGesucht",
  "Defekte Bauteile gesucht": "defekteBauteile",
  "Neues Bauteil eingebaut": "neuesBauteil",
  "Thermorelais eingebaut": "thermorelaisEingebaut",
  "Drehrichtungskontrolle": "drehrichtungskontrolle",
  "Funktionskontrolle": "funktionskontrolle",
  "Isolationsmessung": "isolationsmessung",
  "Inbetriebnahme": "inbetriebnahme",
  "Einzug": "einzug",
  "Instruktion": "instruktion",
  "Fremdmaterial": "fremdmaterial",
  "Messung": "messung",
};

const JobHelper = {
  getJobLabels: () => {
    return jobLabels;
  },

  getKeyFromLabel: (label: string): keyof Job => {
    return jobFieldMapping[label];
  },

  jobFromDto: (jobDto: JobDto): Job => {
    return {
      id: jobDto.id,
      mieleWaschmaschine: jobDto.mieleWaschmaschine,
      schemaunterlagenErgänzt: jobDto.schemaunterlagenErgänzt,
      beschriftung: jobDto.beschriftung,
      materialBestellen: jobDto.materialBestellen,
      stoerungsquelleGesucht: jobDto.stoerungsquelleGesucht,
      defekteBauteile: jobDto.defekteBauteile,
      neuesBauteil: jobDto.neuesBauteil,
      thermorelaisEingebaut: jobDto.thermorelaisEingebaut,
      drehrichtungskontrolle: jobDto.drehrichtungskontrolle,
      funktionskontrolle: jobDto.funktionskontrolle,
      isolationsmessung: jobDto.isolationsmessung,
      inbetriebnahme: jobDto.inbetriebnahme,
      einzug: jobDto.einzug,
      instruktion: jobDto.instruktion,
      fremdmaterial: jobDto.fremdmaterial,
      messung: jobDto.messung,
    };
  },
  
  jobToDto: (job: Job): JobDto => {
    return {
      id: job.id,
      mieleWaschmaschine: job.mieleWaschmaschine,
      schemaunterlagenErgänzt: job.schemaunterlagenErgänzt,
      beschriftung: job.beschriftung,
      materialBestellen: job.materialBestellen,
      stoerungsquelleGesucht: job.stoerungsquelleGesucht,
      defekteBauteile: job.defekteBauteile,
      neuesBauteil: job.neuesBauteil,
      thermorelaisEingebaut: job.thermorelaisEingebaut,
      drehrichtungskontrolle: job.drehrichtungskontrolle,
      funktionskontrolle: job.funktionskontrolle,
      isolationsmessung: job.isolationsmessung,
      inbetriebnahme: job.inbetriebnahme,
      einzug: job.einzug,
      instruktion: job.instruktion,
      fremdmaterial: job.fremdmaterial,
      messung: job.messung,
    };
  },
}

export default JobHelper;