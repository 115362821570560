import { Alert, Box, Button, IconButton, List, ListItemButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MeContext } from "./Provider/MeContextProvider";
import { RapportContext } from "./Provider/RapportContextProvider";
import { Material } from "../models/material";
import { MetaContext } from "./Provider/MetaContextProvider";
import MaterialService from "../services/material-service";
import { Npk } from "../models/npk";
import SvgComponent from "../svg/SvgComponent";

type NpkCatalogProps = {
  isXSScreen: boolean;
  searchValue: string;
};

const NpkCatalog = (props: NpkCatalogProps) => {
  const context = useContext(MetaContext);
  const rapportContext = useContext(RapportContext);
  const meContext = useContext(MeContext);
  const [allMaterial, setAllMaterial] = useState<Npk[]>([]);
  const [loadedMaterial, setLoadedMaterial] = useState<Record<string, Npk[]>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [parent, setParent] = useState<Npk | null>(null);

  const catalogType = 'npk';
  
  const fetchNpkMaterial = async () => {
    const parentId = parent?.catalogId ?? null;
    const key = Object.keys(loadedMaterial).find(key => key === `${parentId}`)
    if (!!key) {
      setAllMaterial(loadedMaterial[`${parentId}`]);
      return;
    }

    const data = await context.handleAsyncOperation(
      () => MaterialService.searchNpkMaterial(parentId ?? undefined)
    );

    if (!!data) {
      setAllMaterial(data);
      setLoadedMaterial(prev => ({ ...prev, [parentId ?? 'null']: data }));
    }
  };

  const fetchSearchedMaterial = async (search?: string) => {
    const data = await context.handleAsyncOperation(
      () => MaterialService.searchNpkMaterial(undefined, undefined, 50, search)
    );

    if (!!data) {
      setAllMaterial(data);
    }
  };

  const addMaterial = async (id: number) => {
    const data = await context.handleAsyncOperation(
      () => MaterialService.getMaterialWithPrice(id, catalogType)
    );

    if (!!data) {
      const newMaterial: Material = { id: 0, catalogId: data.id, displayedProductId: data.catalogId.toString(), catalogType: catalogType, name: data.name, ic: '', amount: "1", price: data.price?.toString() ?? "0", unit: '', };
      rapportContext.updateRapport({...rapportContext.rapport, materials: [ ...rapportContext.rapport.materials, newMaterial ] });
    }
  };

  const searchMaterial = useCallback(async (value?: string) => {
    if (!!value) {
      setIsLoading(true);
      await fetchSearchedMaterial(value);
      setIsLoading(false);
    } else {
      fetchNpkMaterial();
    }
  }, [props.searchValue]);

  const handleEntryClick = (material: Npk) => {
    if (!material.isMaterial) {
      setParent(material);
    }
  };

  const formatId = (id: number) => {
    const idString = id.toString();
    if (idString.length !== 9) {
      return idString;
    }
  
    return `${idString.slice(0, 3)}.${idString.slice(3, 6)}.${idString.slice(6, 9)}`;
  };

  const initialize = async () => {
    if (meContext.currentFirm?.hasNpk) {
      setIsLoading(true);
      await fetchNpkMaterial();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [parent, props.isXSScreen]);

  useEffect(() => {
    searchMaterial(props.searchValue);
  }, [props.searchValue]);
  
  const displayEntry = useCallback((npk: Npk) => {
    const isParent = npk.id === parent?.id;
    const materialName = npk.name !== "" ? npk.name : "(Kein Name)";

    return (
      <Stack>
        <ListItemButton
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            border: 1,
            borderBottom: isParent ? 2 : 1,
            background: isParent ? theme => theme.palette.grey[400] : 'inherit',
          }}
          onClick={() => handleEntryClick(npk)}
        >
          <Tooltip title={materialName}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: isParent ? 0 : 2, alignItems: 'center', flex: 1 }}>
              {isParent && (
                <IconButton onClick={(event) => { setParent(null); event.stopPropagation(); }}>
                  <ArrowBackIosIcon />
                </IconButton>
              )}
              {npk.isMaterial && (
                <Button onClick={() => addMaterial(npk.id)} variant='outlined'>
                  <Box sx={{ display: 'flex', flexDirection: props.isXSScreen ? 'column' : 'row', gap: props.isXSScreen ? 0 : 0.75 }}>
                    <Typography variant='button'>{formatId(npk.catalogId)}</Typography>
                    <Typography variant='button'>hinzufügen</Typography>
                  </Box>
                </Button>
              )}
              <Typography
                sx={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  flex: 1,
                  width:  0,
                }}
              >
                {materialName}
              </Typography>
              {npk.svgData && <SvgComponent svgData={npk.svgData} />}
            </Box>
          </Tooltip>
        </ListItemButton>
      </Stack>
    );
  }, [parent]);

  return (
    <Box>
      {isLoading ?
        <Stack gap={0.5}>
          {[...Array(2)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={53} />
          ))}
        </Stack> :
        <Stack>
          {parent !== null && displayEntry(parent)}
          {(allMaterial.length === 0) && <Alert severity="info" sx={{ alignItems: 'center'}}>Keine Artikel gefunden. Falls Sie nach einer NPK-Nummer suchen möchten, schreiben Sie die Nummer zusammenhängend.</Alert>}
          {allMaterial.map((item, index) =>
            <Box key={index}>
              {displayEntry(item)}
            </Box>
          )}
        </Stack>
      }
    </Box>
  );
};

export default NpkCatalog;