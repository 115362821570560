import { useNavigate } from "react-router-dom";
import Titlebar from "../components/Titlebar";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { AuthContext } from "../components/Provider/AuthContextProvider";

const HomePage: React.FC = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.isAuthenticated) {
      navigate('/overview');
    } else {
      navigate('/login');
    }
  }, [authContext.isAuthenticated]);

  return (
    <Stack>
      <Titlebar title="Startseite" iconName="" />
      <Stack gap={2} padding={2}>
        <Typography>{authContext.isAuthenticated ? "Sie sind bereits eingeloggt." : "Loggen Sie sich zuerst ein."}</Typography>
        <Box gap={2} sx={{display: 'flex'}}>
          <Button variant={authContext.isAuthenticated ? "outlined" : "contained"} onClick={() => navigate('/login')} >Login</Button>
          <Button variant={authContext.isAuthenticated ? "contained" : "outlined"} onClick={() => navigate('/overview')} >Übersicht</Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default HomePage;