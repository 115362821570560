import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { User } from "../../models/user";
import { Address } from "../../models/address";
import { MetaContext } from "./MetaContextProvider";
import AddressService from "../../services/address-service";
import UserFirmService from "../../services/user-firm-service";

type FirmContextType = {
  users: User[];
  setUsers: Dispatch<SetStateAction<User[]>>;
  addressBook: Address[];
  setAddressBook: Dispatch<SetStateAction<Address[]>>;
  addressBookCount: number;
  updatePostItIndex: number | null;
  setUpdatePostItIndex: Dispatch<SetStateAction<number | null>>;
  removeUser: (id: number) => void;
  toggleAdminPermission: (user: User) => void;
  fetchAddressBook: (page: number, pageSize: number, searchTerm?: string) => Promise<void>;
};

export const FirmContext = createContext<FirmContextType>({
  users: [],
  setUsers: () => {},
  addressBook: [],
  setAddressBook: () => {},
  addressBookCount: 0,
  updatePostItIndex: null,
  setUpdatePostItIndex: () => {},
  removeUser: () => {},
  toggleAdminPermission: () => {},
  fetchAddressBook: async () => {},
});

type FirmContextProviderProps = {
  children: ReactNode;
};

const FirmContextProvider = (props: FirmContextProviderProps) => {
  const context = useContext(MetaContext);
  const [users, setUsers] = useState<User[]>([]);
  const [addressBook, setAddressBook] = useState<Address[]>([]);
  const [addressBookCount, setAddressBookCount] = useState(0);
  const [updatePostItIndex, setUpdatePostItIndex] = useState<number | null>(null);

  const removeUser = async (id: number) => {
    await context.handleAsyncOperation(
      () => UserFirmService.removeUser(id),
    );

    setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
  };

  const toggleAdminPermission = async (user: User) => {
    const updatedUser = await context.handleAsyncOperation(
      () => UserFirmService.updateAdminPermission(user.id, !user.isAdmin)
    );

    if (!!updatedUser) {
      setUsers((prevUsers) => (prevUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user))));
    }
  };

  const fetchUsersOfFirm = async () => {
    const firmUsers = await context.handleAsyncOperation(
      UserFirmService.getAllUsers
    );

    if (!!firmUsers) {
      setUsers(firmUsers);
    }
  };

  const fetchAddressBook = async (page?: number, pageSize?: number, searchTerm?: string) => {
    const addressBook = await context.handleAsyncOperation(
      () => AddressService.getAllAddresses(page, pageSize, searchTerm)
    );
    
    if (!!addressBook) {
      setAddressBook(addressBook);
    }
  };

  const fetchAddressCount = async () => {
    const data = await context.handleAsyncOperation(
      AddressService.getAddressCount
    );

    if (!!data) {
      setAddressBookCount(data);
    }
  };

  useEffect(() => {
    fetchAddressBook(1, 100);
    fetchAddressCount();
    fetchUsersOfFirm();
  }, []);

  return (
    <FirmContext.Provider
      value={{
        users,
        setUsers,
        addressBook,
        setAddressBook,
        addressBookCount,
        updatePostItIndex,
        setUpdatePostItIndex,
        removeUser,
        toggleAdminPermission,
        fetchAddressBook,
      }}
    >
      {props.children}
    </FirmContext.Provider>
  );
}

export default FirmContextProvider;
