import { Box, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { addDays, startOfWeek } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

type WorktimeWeeklyProps = {
  height: number;
  targetTime: number;
  workHours: number[];
  currentDay: Date;
  setCurrentDay: Dispatch<SetStateAction<Date>>;
  createWorktime: (date: Date) => void;
};

const WorktimeWeekly = (props: WorktimeWeeklyProps) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const longDaysOfWeek = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
  const shortDaysOfWeek = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
  const daysOfWeek = isXs ? shortDaysOfWeek : longDaysOfWeek;

  const [series, setSeries] = useState<{ data: number[] }[]>([{ data: [0] }]);
  const [options, setOptions] = useState<ApexOptions>({});
  const [isLoading, setIsLoading] = useState(true);

  const colors = (value: any) => {
    if (Number(value.value) >= props.targetTime) {
      return theme.palette.primary.main;
    }

    if (value.seriesIndex === 5 || value.seriesIndex === 6) {
      return theme.palette.grey[400];
    }

    return theme.palette.grey[800];
  };

  const formatHours = (minutes: number): string => {
    if (minutes <= 0) {
      return '';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = (minutes % 60).toString().padStart(2, '0');
  
    if (isXs && hours === 0) {
      return '';
    }

    return isXs ? `${hours}h` : `${hours}h ${remainingMinutes}m`;
  };

  const getNewCurrentDay = (index: number): Date => {
    return addDays(startOfWeek(props.currentDay, { weekStartsOn: 1 }), index);
  };

  const apexOptions: ApexOptions = {
    chart: {
      events: {
        dataPointSelection: (event: any, chartContext: any, config: any) => {
          const day = getNewCurrentDay(config.dataPointIndex);
          props.setCurrentDay(day);
        },
        xAxisLabelClick: (event: any, chartContext: any, config: any) => {
          const day = getNewCurrentDay(config.labelIndex);
          props.setCurrentDay(day);
          props.createWorktime(day);
        },
      },
      toolbar: {
        show: false,
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '65%',
        distributed: true,
      },
    },
    colors: [colors],
    dataLabels: {
      enabled: true,
      formatter: (value: any) => {
        if (value > 0) {
          return formatHours(value);
        }
        return '';
      },
    },
    yaxis: {
      labels: {
        formatter: (val: number) => `${(val / 60).toFixed(1)}h`,
      },
      stepSize: isXs ? 120 : 60,
      max: 600,
    },
    xaxis: {
      categories: daysOfWeek,
      labels: {
        style: {
          colors: ['#000000', '#000000', '#000000', '#000000', '#000000', '#999999', '#999999'],
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    annotations: {
      yaxis: [{
        y: props.targetTime,
        borderColor: 'black',
        label: {
          text: `Sollzeit: ${formatHours(props.targetTime)}`,
          style: {
            color: '#FFFFFF',
            background: '#666666',
          },
        },
      }],
    },
    legend: {
      show: false,
    },
  };

  useEffect(() => {
    setSeries([{ data: props.workHours }]);
    setOptions(apexOptions);
    setIsLoading(false);
  }, [props.currentDay, props.workHours]);

  return (
    (isLoading) ?  
    (<Box display='flex' gap={1}>
      <Box width='10%'>
        <Skeleton sx={{ height: isXs ? 215 : 365 }} variant="rectangular" />
      </Box>
      <Stack flex={1} gap={1}>
        <Skeleton sx={{ height: '80%' }} variant="rectangular" />
        <Skeleton sx={{ height: '20%' }} variant="rectangular" />
      </Stack>
    </Box>) :
    (<ReactApexChart options={options} series={series} type="bar" height={props.height} />)
  );
};

export default WorktimeWeekly;