import Titlebar from "../components/Titlebar";
import { Alert, Button, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Row, SchochStack } from "../styles/styles";
import { useState, ChangeEvent, useContext } from "react";
import { MetaContext } from "../components/Provider/MetaContextProvider";
import UserService from "../services/user-service";
import { defaultResetPassword, ResetPassword } from "../models/reset-password";
import UserFirmService from "../services/user-firm-service";
import { useNavigate } from "react-router-dom";

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const context = useContext(MetaContext);
  const [resetPassword, setResetPassword] = useState<ResetPassword>(defaultResetPassword);
  const [error, setError] = useState<string | undefined>(undefined);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  
  const updateFirmLicence = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setResetPassword({...resetPassword, firmLicence: newValue});
  };

  const updateUserLicence = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setResetPassword({...resetPassword, userLicence: newValue});
  };

  const updatePassword = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setResetPassword({...resetPassword, password: newValue});
  };

  const updateConfirmPassword = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setResetPassword({...resetPassword, confirmPassword: newValue});
  };

  const validatePassword = (): boolean => {
    const errorMessage = UserService.validatePassword(resetPassword.password, resetPassword.confirmPassword);

    setError(errorMessage);
    return !!!errorMessage;
  };

  const handleResetClick = async () => {
    const validPassword = validatePassword(); 
    if (!validPassword) {
      return;
    }

    var success = await context.handleAsyncOperation(
      () => UserFirmService.resetPassword(resetPassword)
    );

    if (!success) {
      setError("Der Account wurde nicht gefunden.");
    } else {
      navigate("/login");
      context.setAlertMessage("Das Passwort wurde erfolgreich zurückgesetzt.");
      context.setAlertSeverity("success");
    }
  };

  return (
    <Stack>
      <Titlebar title="Passwort zurücksetzen" iconName="" />
      <SchochStack sx={{ alignSelf: { xs: 'none', sm: 'center' }, width: { sm: 450 } }}>
        <Row>
          <Typography sx={{width: 180, alignSelf: 'center', display: isXs ? "none" : "flex" }}>Firmen-Lizenz</Typography>
          <TextField
            sx={{ flex: 1 }}
            label={isXs ? "Firmen-Lizenz" : ""}
            type="text"
            onChange={updateFirmLicence}
            size="small"
          />
        </Row>
        <Row>
          <Typography sx={{width: 180, alignSelf: 'center', display: isXs ? "none" : "flex" }}>Benutzer-Lizenz</Typography>
          <TextField
            sx={{ flex: 1 }}
            type="text"
            label={isXs ? "Benutzer-Lizenz" : ""}
            onChange={updateUserLicence}
            size="small"
          />
        </Row>
        <Row>
          <Typography sx={{width: 180, alignSelf: 'center', display: isXs ? "none" : "flex" }}>Neues Passwort</Typography>
          <TextField
            sx={{ flex: 1 }}
            type="password"
            label={isXs ? "Neues Passwort" : ""}
            onChange={updatePassword}
            size="small"
          />
        </Row>
        <Row>
          <Typography sx={{width: 180, alignSelf: 'center', display: isXs ? "none" : "flex" }}>Passwort bestätigen</Typography>
          <TextField
            sx={{ flex: 1 }}
            type="password"
            label={isXs ? "Passwort bestätigen" : ""}
            onChange={updateConfirmPassword}
            size="small"
          />
        </Row>
        <Row gap={2}>
          <Button
            sx={{ width: '50%', background: theme => theme.palette.background.paper, }}
            variant="outlined"
            onClick={() => navigate("/login")}>
            Zum Login
          </Button>
          <Button
            sx={{ width: '50%'}}
            variant="contained"
            onClick={handleResetClick}>
            Zurücksetzen
          </Button>
        </Row>
        {!!error && <Alert severity="error">{error}</Alert>}
      </SchochStack>
    </Stack>
  );
};

export default ResetPasswordPage;