import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import MetaContextProvider from './components/Provider/MetaContextProvider';
import MeContextProvider from './components/Provider/MeContextProvider';
import * as serviceWorker from './serviceWorkerRegistration';
import AuthContextProvider from './components/Provider/AuthContextProvider';
import router from './router';
import ThemeContextProvider from './components/Provider/ThemeContextProvider';

const domNode = document.getElementById('root') as Element;
const root = createRoot(domNode);

root.render(
  <MetaContextProvider>
    <AuthContextProvider>
      <MeContextProvider>
        <ThemeContextProvider>
          <RouterProvider router={router} />
        </ThemeContextProvider>
      </MeContextProvider>
    </AuthContextProvider>
  </MetaContextProvider>
);

serviceWorker.register();