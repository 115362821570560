import UserAPI from "../api/user-api";
import { UpdateUserDto } from "../dto/users/update-user-dto";
import LocalStorageHelper from "../helpers/local-storage-helper";
import UserHelper from "../helpers/user-helper";
import { User } from "../models/user";

const UserService = {
  getCurrentUser: async (): Promise<User> => {
    const userDto = await UserAPI.getCurrentUser();
    return UserHelper.userFromDto(userDto);
  },

  updateUser: async (firstName: string, lastName: string, username: string): Promise<number> => {
    const user = {firstName, lastName, username} as UpdateUserDto;
    return await UserAPI.updateUser(user);
  },

  deleteUser: async () => {
    await UserAPI.deleteUser();
  },

  usernameExists: async (username: string): Promise<boolean> => {
    return await UserAPI.usernameExists(username);
  },

  validatePassword: (password: string, confirmPassword: string): string | undefined => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*_+-])(.{8,})$/;
    
    let errorMessage = undefined;
    if (!passwordRegex.test(password)) {
      errorMessage = 'Das Passwort muss aus mind. 8 Zeichen, 1 Grossbuchstaben und einem Spezialzeichen bestehen.';
    } else if (password !== confirmPassword) {
      errorMessage = 'Die Passwörter stimmen nicht überein.';
    }

    return errorMessage;
  },

  setDarkMode: (isDarkMode: boolean) => {
    LocalStorageHelper.setDarkMode(isDarkMode);
  },
  
  getDarkMode: (): boolean => {
    return LocalStorageHelper.getDarkMode();
  },
};

export default UserService;