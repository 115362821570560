import { FormControlLabel, FormGroup, Stack, Checkbox } from '@mui/material';
import { useContext, useEffect } from 'react';
import { RapportContext } from '../Provider/RapportContextProvider';
import { SchochStack } from '../../styles/styles';
import { Job } from '../../models/job';
import { ItemProps } from '../Dialogs/ItemDialog';
import JobHelper from '../../helpers/job-helper';
import RapportService from '../../services/rapport-service';

const JobsItem = (props: ItemProps) => {
  const rapportContext = useContext(RapportContext);

  const handleSwitchChange = (field: keyof Job) => (event: React.ChangeEvent<HTMLInputElement>) => {
    rapportContext.updateRapport({ ...rapportContext.rapport, jobs: { ...rapportContext.rapport.jobs, [field]: event.target.checked, } });
  };

  useEffect(() => {
    const isFilled = RapportService.isJobFilled(rapportContext.rapport);
    props.updateIsFilled(isFilled);
  }, [rapportContext.rapport.jobs]);

  return (
    <Stack gap={2} marginTop={2}>
      <SchochStack>
        <FormGroup>
          {JobHelper.getJobLabels().map((label) => {
            const field = JobHelper.getKeyFromLabel(label);
            return (
              <FormControlLabel
                key={label}
                control={<Checkbox checked={Boolean(rapportContext.rapport.jobs[field])} onChange={handleSwitchChange(field)} />}
                label={label}
              />
            );
          })}
        </FormGroup>
      </SchochStack>
    </Stack>
  );
};

export default JobsItem;
