import { Paper, Stack, Box, Typography, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Chip, List, ListItem, Divider } from "@mui/material";
import JobHelper from "../helpers/job-helper";
import RapportService from "../services/rapport-service";
import { Row } from "../styles/styles";
import DynamicIcon from "./DynamicIcon";
import { Rapport } from "../models/rapport";
import { Signature } from "../models/signature";
import { useState } from "react";
import RapportViewAddress from "./RapportViewAddress";
import { PriceTotal } from "../models/price-total";

type RapportViewProps = {
  rapport: Rapport;
  priceTotal: PriceTotal;
  signature: Signature | null;
};

const RapportView = (props: RapportViewProps) => {
  const [showWorktimes, setShowWorktimes] = useState(true);
  const [showMaterial, setShowMaterial] = useState(true);

  return (
    <Paper elevation={3}>
    <Stack sx={{ padding: 4, gap: 4, }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Service-Rapport
        </Typography>
        <Box sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
          <Typography>
            {props.rapport.dueDate?.toLocaleDateString('de-DE', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            }) ?? "(Kein Datum)"}
          </Typography>
          <Typography>{props.rapport.emilId}{props.rapport.projectId.trim() !== '' ? ` (${props.rapport.projectId})` : ''}</Typography>
        </Box>
      </Box>
      {(props.rapport.description.trim() !== "" && props.rapport.betreff.trim() !== "") && <Stack gap={2}>
        <Row alignItems='center' gap={2}>
          <DynamicIcon iconName='Description' />
          <Typography variant='h6'>{props.rapport.betreff.trim() !== "" ? props.rapport.betreff : "(Kein Betreff)"}</Typography>
        </Row>
        <Typography variant='subtitle1'>{props.rapport.description.trim() !== "" ? props.rapport.description : "(Keine Beschreibung)"}</Typography>
      </Stack>}

      <RapportViewAddress rapport={props.rapport} />

      {props.rapport.worktimes.length > 0 && <Stack gap={2}>
        <Row sx={{ justifyContent: 'space-between', gap: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', flex: 1, }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <DynamicIcon iconName='HourglassBottom' />
              <Typography variant='h6'>Arbeitszeit</Typography>
            </Box>
              {!props.rapport.showPrices && <Typography sx={{ alignSelf: {xs: 'flex-start', sm: 'center' } }} variant="caption">Preise werden nicht angezeigt</Typography>}
          </Box>
          {<IconButton onClick={() => setShowWorktimes(prev => !prev)}>
              <DynamicIcon iconName={showWorktimes ? "KeyboardArrowUp" : "KeyboardArrowDown"} />
            </IconButton>}
        </Row>
        {showWorktimes && <Table sx={{ border: 1, boxShadow: 2 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: theme => theme.palette.grey[400], borderBottom: 2, }}>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Name
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                Betrag
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rapport.worktimes.map((worktime, index) => (
              <TableRow key={index} sx={{ '&:last-child td': { border: 0 }, '&:nth-of-type(even)': { background: theme => theme.palette.grey[50] } }}>
                <TableCell colSpan={2} align="left" sx={{ maxWidth: 0 }}>
                  <Stack sx={{ gap: 0.5 }}>
                    <Box sx={{ fontWeight: 'bold' }}>
                      <Tooltip title={worktime.username.trim() !== "" ? worktime.username : "-"}>
                        <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 'auto', }}>
                          {worktime.username.trim() !== "" ? worktime.username : "-"}
                        </Box>
                      </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.875rem', color: 'text.secondary' }}>
                      <Box>
                        {worktime.productId.trim() !== "" ? `${worktime.productId} | ` : ''}{Number(worktime.timeInHours).toFixed(2)}{'h '}
                        {props.rapport.showPrices ? `(${Number(worktime.price).toFixed(2)} CHF)` : '-'}
                      </Box>
                      <Box>
                        {props.rapport.showPrices ? `${(Number(worktime.price) * Number(worktime.timeInHours)).toFixed(2)} CHF` : '-'}
                      </Box>
                    </Box>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {props.rapport.showPrices && <TableRow sx={{ '&:last-child td': { border: 0 }, borderTop: 2 }}>
              <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="right">{props.priceTotal.worktimeTotal.toFixed(2)}{' CHF'}</TableCell>
            </TableRow>}
          </TableBody>
        </Table>}
      </Stack>}

      {props.rapport.materials.length > 0 && <Stack gap={2}>
        <Row sx={{ justifyContent: 'space-between', gap: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', flex: 1, }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <DynamicIcon iconName='ElectricalServices' />
              <Typography variant='h6'>Material</Typography>
            </Box>
              {!props.rapport.showPrices && <Typography sx={{ alignSelf: { xs: 'flex-start', sm: 'center' } }} variant="caption">Preise werden nicht angezeigt</Typography>}
          </Box>
          {<IconButton onClick={() => setShowMaterial(prev => !prev)}>
            <DynamicIcon iconName={showMaterial ? "KeyboardArrowUp" : "KeyboardArrowDown"} />
          </IconButton>}
        </Row>
        {showMaterial &&
          <Table sx={{ border: 1, boxShadow: 2, }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: theme => theme.palette.grey[400], borderBottom: 2, }}>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Material
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                Betrag
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rapport.materials.map((material, index) => (
              <TableRow key={index} sx={{ '&:last-child td': { border: 0 }, '&:nth-of-type(even)': { background: theme => theme.palette.grey[50] } }}>
                <TableCell colSpan={2} align="left" sx={{ maxWidth: 0 }}>
                  <Stack sx={{ gap: 0.5 }}>
                    <Box sx={{ fontWeight: 'bold' }}>
                      <Tooltip title={material.name.trim() !== "" ? `${material.name}` : "-"}>
                        <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 'auto', }}>
                          {material.name.trim() !== "" ? `${material.name}` : "-"}
                        </Box>
                      </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.875rem', color: 'text.secondary' }}>
                      <Box>
                        {material.displayedProductId.trim() !== "" ? `${material.displayedProductId} | ` : ''}{material.amount}{' '}
                        {props.rapport.showPrices ? `(${Number(material.price).toFixed(2)} CHF)` : ''}
                      </Box>
                      {props.rapport.showPrices ? `${(Number(material.price) * Number(material.amount)).toFixed(2)} CHF` : '-'}
                    </Box>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {props.rapport.showPrices && (
              <TableRow sx={{ '&:last-child td': { border: 0 }, borderTop: 2 }}>
                <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align="right">
                  {props.priceTotal.materialTotal.toFixed(2)}{' CHF'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        }
      </Stack>}
      {props.rapport.showPrices && <Stack gap={2}>
        <Row alignItems='center' gap={2}>
          <DynamicIcon iconName='LocalOffer' />
          <Typography variant='h6'>Gesamtbetrag</Typography>
        </Row>
        <Stack gap={1}>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, }}>
              <Typography>Arbeitszeit</Typography>
            </Box>
            <Box sx={{ display: props.rapport.showPrices ? 'flex' : 'none', flexDirection: 'row', gap: 2 }}>
              <Typography>{props.priceTotal.worktimeTotal.toFixed(2)}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, }}>
              <Typography>Material</Typography>
            </Box>
            <Box sx={{ display: props.rapport.showPrices ? 'flex' : 'none', flexDirection: 'row', gap: 2 }}>
              <Typography>{props.priceTotal.materialTotal.toFixed(2)}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
          <Divider />
          {props.rapport.discount > 0 && <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', margin: 0, gap: 2, alignItems: 'center' }}>
              <Typography>Rabatt</Typography>
              <Chip
                label={props.rapport.discount + '%'}
                size="small"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>-{props.priceTotal.discountAmount.toFixed(2)}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>}
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Typography>Brutto</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>{props.priceTotal.netTotal.toFixed(2)}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>MWST</Typography>
              <Chip
                label={props.rapport.vat + '%'}
                size="small"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>{props.priceTotal.vatAmount.toFixed(2)}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
          <Divider />
          <Row sx={{ flex: 1, justifyContent: 'space-between' }}>
            <Typography fontWeight='bold'>Netto</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography fontWeight='bold'>{props.priceTotal.total.toFixed(2)}</Typography>
              <Typography fontWeight='bold'>CHF</Typography>
            </Box>
          </Row>
        </Stack>
      </Stack>}
      {RapportService.isRapportInfoFilled(props.rapport) && <Stack gap={2}>
        <Row alignItems='center' gap={2}>
          <DynamicIcon iconName='Info' />
          <Typography variant='h6'>Auftragsdaten</Typography>
        </Row>
        <Stack>
          {props.rapport.createDate !== null && <Typography><strong>Auftrag erteilt am:</strong> {props.rapport.createDate.toLocaleDateString('de-DE', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Typography>}
          {props.rapport.reporter.trim() !== "" && <Typography><strong>Auftrag erteilt durch:</strong> {props.rapport.reporter}</Typography>}
          {props.rapport.creator !== null && <Typography><strong>Auftrag erfasst durch:</strong> {props.rapport.creator.username}</Typography>}
          {props.rapport.phoneNr.trim() !== "" && <Typography><strong>Telefon-Nr.:</strong> {props.rapport.phoneNr}</Typography>}
          {props.rapport.cost.trim() !== "" && <Typography><strong>Kostenstelle:</strong> {props.rapport.cost}</Typography>}
          {props.rapport.counter.trim() !== "" && <Typography><strong>Zähler-Nr:</strong> {props.rapport.counter}</Typography>}
          {props.rapport.more.trim() !== "" && <Typography><strong>Weitere Informationen:</strong> {props.rapport.more}</Typography>}
        </Stack>
      </Stack>}
      {RapportService.isJobFilled(props.rapport) && <Stack gap={2}>
        <Row alignItems='center' gap={2}>
          <DynamicIcon iconName='DesignServices' />
          <Typography variant='h6'>Tätigkeiten</Typography>
        </Row>
        <Row sx={{ flexWrap: 'wrap', gap: 1, }}>
        {JobHelper.getJobLabels()
            .filter((label) => JobHelper.getKeyFromLabel(label) && props.rapport.jobs[JobHelper.getKeyFromLabel(label)])
            .map((job, index) => <Chip key={index} label={job} />)}
        </Row>
      </Stack>}
      {RapportService.isMeasurementFilled(props.rapport) && <Stack gap={2}>
        <Row alignItems='center' gap={2}>
          <DynamicIcon iconName='Straighten' />
          <Typography variant='h6'>Messungen</Typography>
        </Row>
        <Row sx={{ flexWrap: 'wrap', gap: 1, }}>
          {[
            { label: "FI Auslösestärke", value: props.rapport.measurements.fi },
            { label: "Sichtkontrolle nach NIN", value: props.rapport.measurements.nin },
            { label: "TN-S", value: props.rapport.measurements.tnS },
            { label: "TN-C", value: props.rapport.measurements.tnC },
            { label: "TN-C-S", value: props.rapport.measurements.tnCS },
            { label: "Zähler-Nr.", value: props.rapport.measurements.counter },
            { label: "In (A), PE geprüft", value: props.rapport.measurements.inA },
            { label: "R iso", value: props.rapport.measurements.rIso },
            { label: "IK (A)", value: props.rapport.measurements.ikA },
            { label: "M Ohm", value: props.rapport.measurements.mOhm },
          ].map((field, index) => (
            field.value && String(field.value).trim() !== "" && (
              <Chip key={index} label={`${field.label}: ${field.value}`} />
            )
          ))}
        </Row>
      </Stack>}
      {props.signature !== null && <Stack gap={2}>
        <Row alignItems='center' gap={2}>
          <DynamicIcon iconName='HistoryEdu' />
          <Typography variant='h6'>Unterschrift</Typography>
        </Row>
        <img 
          src={`data:image/png;base64,${props.signature.content}`} 
          alt="Signature" 
          style={{ width: '100%', border: 1, borderColor: 'black' }}
        />
      </Stack>}
    </Stack>
  </Paper>
  );
};

export default RapportView;