export type ResetPassword = {
  firmLicence: string;
  userLicence: string;
  password: string;
  confirmPassword: string;
};

export const defaultResetPassword: ResetPassword = {
  firmLicence: '',
  userLicence: '',
  password: '',
  confirmPassword: '',
};