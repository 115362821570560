import { Alert, Box, Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useContext, useEffect, useState } from "react";
import { RapportContext } from "../Provider/RapportContextProvider";
import { Worktime } from "../../models/worktime";
import WorktimeDialog from "../Dialogs/WorktimeDialog";
import { SchochStack } from "../../styles/styles";
import { formatHours } from "../../helpers/utils";
import { ItemProps } from "../Dialogs/ItemDialog";

const WorktimeItem = (props: ItemProps) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const rapportContext = useContext(RapportContext);
  const [openWorktime, setOpenWorktime] = useState(false);
  const [currentWorktime, setCurrentWorktime] = useState<Worktime | null>(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isNameCategorized, setNameCategorized] = useState(false);

  const MILLISECSINADAY = 24 * 60 * 60 * 1000;

  const formatDate = (category: number) => {
    return (category !== -1) 
    ? new Date(category * MILLISECSINADAY).toLocaleDateString('de-DE', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' })
    : 'Ohne Datum';
  };

  const categorizedByName: Record<string, Worktime[]> = {};
  rapportContext.rapport.worktimes.filter(x => !!!x.isRemoved).forEach((worktime) => {
    const key = (worktime.username !== "") ? (`${worktime.username}${worktime.productId !== "" ? ` (${worktime.productId})` : ""}`) : '(Kein Name)';
    if (!categorizedByName[key]) {
      categorizedByName[key] = [];
    }

    categorizedByName[key].push(worktime);
  });
  const categorizedByNameEntries = Object.entries(categorizedByName) as [string, Worktime[]][];

  Object.keys(categorizedByName).forEach(key => {
    categorizedByName[key].sort((a, b) => {
      if (a.date === null) return 1;
      if (b.date === null) return -1;
      return b.date.getTime() - a.date.getTime();
    });
  });

  const categorizedByDate: Record<number, Worktime[]> = {};
  rapportContext.rapport.worktimes.filter(x => !!!x.isRemoved).forEach((worktime) => {
    const key = (worktime.date !== null) ? Math.floor(worktime.date.getTime() / MILLISECSINADAY) : -1;
    if (!categorizedByDate[key]) {
      categorizedByDate[key] = [];
    }

    categorizedByDate[key].push(worktime);
  });
  const categorizedByDateEntries = Object.entries(categorizedByDate) as [string, Worktime[]][];
  categorizedByDateEntries.sort(([keyA], [keyB]) => Number(keyB) - Number(keyA));

  const categorized = isNameCategorized ? categorizedByNameEntries : categorizedByDateEntries;

  const totalTime = (worktimes: Worktime[]): number => {
    let result = 0;
    worktimes.forEach((worktime) => {
      result += Number(worktime.timeInHours);
    });

    return result;
  };

  const handleEditClick = (worktime: Worktime, index: number) => {
    setCurrentWorktime(worktime);
    setCurrentIndex(index);
    setOpenWorktime(true);
  };

  const handleDeleteClick = (worktime: Worktime, index: number) => {
    if (worktime.id === 0) {
      rapportContext.updateRapport({ ...rapportContext.rapport, worktimes: [ ...rapportContext.rapport.worktimes.filter((_, i) => i !== index) ] });
    } else {
      rapportContext.updateRapport({ ...rapportContext.rapport, worktimes: [ ...rapportContext.rapport.worktimes.map(x => x.id === worktime.id ? { ...worktime, isRemoved: true } : x) ] });
    }
  };

  const saveWorktime = (worktime: Worktime, index: number) => {
    const checkedWorktime = { ...worktime, timeInHours: worktime.timeInHours };

    if (currentWorktime === null) { // Info: Save new entry
      rapportContext.updateRapport({...rapportContext.rapport, worktimes: [ ...rapportContext.rapport.worktimes, checkedWorktime ] });
    } else if (worktime.id === 0) { // Info: Save edits to new entry
      rapportContext.updateRapport({...rapportContext.rapport, worktimes: rapportContext.rapport.worktimes.map((x, i) => i === index ? checkedWorktime : x) });
    } else { // Info: Save existing db entries
      rapportContext.updateRapport({ ...rapportContext.rapport, worktimes: rapportContext.rapport.worktimes.map(x => x.id === checkedWorktime.id ? { ...checkedWorktime, isChecked: false } : x) });
    }

    setCurrentWorktime(null);
    setCurrentIndex(-1);
    setOpenWorktime(false);
  };

  useEffect(() => {
    const isFilled = rapportContext.rapport.worktimes.length > 0;
    props.updateIsFilled(isFilled);
  }, [rapportContext.rapport.worktimes]);

  return (
    <Stack gap={2} marginTop={2}>
      <SchochStack>
        <Box>
          <IconButton onClick={() => setOpenWorktime(true)}>
            <AddIcon />
          </IconButton>
          <IconButton onClick={() => setNameCategorized(prev => !prev)}>
            <SortByAlphaIcon />
          </IconButton>
        </Box>
      </SchochStack>
      <Stack gap={2}>
        {rapportContext.rapport.worktimes.filter(x => !!!x.isRemoved).length === 0 && <Alert severity="info" variant="outlined">Keine Arbeitszeit erfasst</Alert>}
        {categorized.map(([category, worktimes], index) => (
          <SchochStack key={index}>
            <Box display='flex' flexDirection='row' justifyContent='space-between' gap={2}>
              <Typography variant="h6" noWrap>{isNameCategorized ? category : formatDate(Number(category))}</Typography>
              <Typography variant="h6" sx={{ whiteSpace: 'nowrap' }}>{formatHours(totalTime(worktimes))}</Typography>
            </Box>
            <Stack>
              {worktimes.map((worktime, index) => (
                <Stack key={index}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 2, paddingBottom: 1, paddingTop: 1 }}>
                    <Typography fontWeight='bold' noWrap>{isNameCategorized ? (worktime.date.toLocaleDateString('de-DE', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' })) : (worktime.username !== "" ? (`${worktime.username}${worktime.productId !== "" ? ` (${worktime.productId})` : ""}`) : '(Kein Name)')}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between', flex: 1 }}>
                      <Box flex={1} width={0}>
                        <Typography noWrap>
                        {isXs ? "" : (!!worktime.description ? worktime.description : "(Keine Beschreibung)")}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography fontWeight='bold' sx={{ whiteSpace: 'nowrap'}}>{formatHours(Number(worktime.timeInHours))}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Divider orientation="vertical" flexItem />
                      <IconButton onClick={() => handleEditClick(worktime, index)}>
                        <EditIcon />
                      </IconButton>
                      <Divider orientation="vertical" flexItem />
                      <IconButton onClick={() => handleDeleteClick(worktime, index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  {(index !== worktimes.length - 1) && <Divider orientation="horizontal" />}
                </Stack>              
              ))}
            </Stack>
          </SchochStack>
        ))}
      </Stack>
      <WorktimeDialog worktime={currentWorktime} index={currentIndex} lockedName={false} isOpen={openWorktime} onClose={() => {setOpenWorktime(false); setCurrentWorktime(null);}} onSave={saveWorktime} />
    </Stack>
  );
};

export default WorktimeItem;
