import React, { useCallback, useEffect, useRef } from "react";
import { useBeforeUnload, useBlocker, Navigator } from "react-router-dom";

type UsePromptOptions = {
  beforeUnload?: boolean;
};

type UsePromptReturn = void;

const usePrompt = (
  message: string | false,
  { beforeUnload }: UsePromptOptions = {}
): UsePromptReturn => {
  const blocker = useBlocker(
    useCallback(
      () => (typeof message === "string" ? !window.confirm(message) : false),
      [message]
    )
  );
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  useBeforeUnload(
    useCallback(
      (event: BeforeUnloadEvent) => {
        if (beforeUnload && typeof message === "string") {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, beforeUnload]
    ),
    { capture: true }
  );
};

type PromptProps = {
  when: boolean;
  message: string;
  beforeUnload?: boolean;
};

const Prompt: React.FC<PromptProps> = ({ when, message, beforeUnload }) => {
  usePrompt(when ? message : false, { beforeUnload });
  return null;
};

export default Prompt;