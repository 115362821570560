import { UserDto } from "../dto/users/user-dto";
import { FirmDto } from "../dto/firm-dto";
import authorizedApi from "./api";
import { UserFirmConnectDto } from "../dto/users/user-firm-connect-dto";
import axios from "axios";
import config from "../config";
import { UserFirmJunctionDto } from "../dto/users/user-firm-junction-dto";
import { WorkloadAmountDto } from "../dto/workload-amount-dto";
import { ResetPasswordDto } from "../dto/users/reset-password-dto";

const UserFirmAPI = {
  getAllUsers: async (searchTerm?: string): Promise<UserDto[]> => {
    const response = await authorizedApi.get(`user-firm`, { params: { searchTerm }});
    return response.data as UserDto[];
  },

  getFirmOfUser: async (): Promise<FirmDto | null> => {
    try {
      const response = await authorizedApi.get(`/user-firm/firm`);
      if (response.status === 204) {
        return null;
      }
  
      return response.data as FirmDto;
    } catch {
      return null;
    }
  },

  getUserLicence: async (): Promise<string | null> => {
    const response = await authorizedApi.get(`/user-firm/user-licence`);
    if (response.status === 204) {
      return null;
    }

    return response.data as string;
  },

  getMemberCount: async (admin?: boolean): Promise<number | null> => {
    let queryParams = {};
    if (admin !== undefined) {
      queryParams = { admin: admin };
    }
    
    const response = await authorizedApi.get(`/user-firm/member-count`, { params: queryParams });
    if (response.status === 204) {
      return null;
    }

    return response.data as number;
  },

  licenceAvailableQuarantine: async (userFirmConnectDto: UserFirmConnectDto): Promise<boolean> => {
    const response = await axios.post(`${config.apiUrl}/user-firm/licence-available`, userFirmConnectDto);
    return response.data as boolean;
  },

  joinFirm: async (userFirmJunctionDto: UserFirmJunctionDto): Promise<boolean> => {
    const response = await authorizedApi.post(`/user-firm/join`, userFirmJunctionDto);
    return response.data as boolean;
  },

  leaveFirm: async (userFirmJunctionDto: UserFirmJunctionDto): Promise<boolean> => {
    const response = await authorizedApi.post(`/user-firm/leave`, userFirmJunctionDto);
    return response.data as boolean;
  },

  removeUser: async (userId: number) => {
    await authorizedApi.post(`/user-firm/${userId}/kick`);
  },

  resetPassword: async (resetPasswordDto: ResetPasswordDto): Promise<boolean> => {
    const response = await axios.put(`${config.apiUrl}/user-firm/password`, resetPasswordDto);
    return response.data as boolean;
  },

  updateAdminPermission: async (userId: number, isAdmin: boolean): Promise<UserDto> => {
    const response = await authorizedApi.put(`/user-firm/${userId}/admin`, { isAdmin });
    return response.data as UserDto;
  },

  updateWorkloadAmount: async (userId: number, workloadAmountDto: WorkloadAmountDto): Promise<UserDto>  => {
    const response = await authorizedApi.put(`/user-firm/${userId}/workload`, workloadAmountDto);
    return response.data as UserDto;
  },
};

export default UserFirmAPI;