import { useContext, useState } from "react";
import { Box, Button, FormControlLabel, Stack, Switch, TextField, } from "@mui/material";
import Titlebar from "../components/Titlebar";
import { Row, SchochStack } from "../styles/styles";
import { ThemeContext } from "../components/Provider/ThemeContextProvider";

const SettingsPage: React.FC = () => {
  const themeContext = useContext(ThemeContext);
  const [invalidColors, setInvalidColors] = useState<string[]>([]);

  const [primaryMain, setPrimaryMain] = useState("#FF9800");
  const [primaryLight, setPrimaryLight] = useState("#FFA500");
  const [primaryDark, setPrimaryDark] = useState("#F57C00");
  const [primaryDarker, setPrimaryDarker] = useState("#F4511E");
  const [primaryLighter, setPrimaryLighter] = useState("#FFCC80");

  const [successMain, setSuccessMain] = useState("#4CAF50");
  const [errorMain, setErrorMain] = useState("#F44336");

  const [backgroundDefault, setBackgroundDefault] = useState("#333333");
  const [backgroundPaper, setBackgroundPaper] = useState("#444444");

  const [textPrimary, setTextPrimary] = useState("#EEEEEE");
  const [textSecondary, setTextSecondary] = useState("#DDDDDD");

  const [grey50, setGrey50] = useState("#555555");
  const [grey100, setGrey100] = useState("#666666");
  const [grey200, setGrey200] = useState("#777777");
  const [grey400, setGrey400] = useState("#888888");
  const [grey800, setGrey800] = useState("#999999");

  const handleColorChange = (colorType: string, newColor: string) => {
    switch (colorType) {
      case "primaryMain":
        setPrimaryMain(newColor);
        break;
      case "primaryLight":
        setPrimaryLight(newColor);
        break;
      case "primaryDark":
        setPrimaryDark(newColor);
        break;
      case "primaryDarker":
        setPrimaryDarker(newColor);
        break;
      case "primaryLighter":
        setPrimaryLighter(newColor);
        break;
      case "successMain":
        setSuccessMain(newColor);
        break;
      case "errorMain":
        setErrorMain(newColor);
        break;
      case "backgroundDefault":
        setBackgroundDefault(newColor);
        break;
      case "backgroundPaper":
        setBackgroundPaper(newColor);
        break;
      case "textPrimary":
        setTextPrimary(newColor);
        break;
      case "textSecondary":
        setTextSecondary(newColor);
        break;
      case "grey50":
        setGrey50(newColor);
        break;
      case "grey100":
        setGrey100(newColor);
        break;
      case "grey200":
        setGrey200(newColor);
        break;
      case "grey400":
        setGrey400(newColor);
        break;
      case "grey800":
        setGrey800(newColor);
        break;
      default:
        break;
    }

    if (isValidColor(newColor)) {
      setInvalidColors((prev) => prev.filter((type) => type !== colorType));
    } else {
      if (!invalidColors.includes(colorType)) {
        setInvalidColors((prev) => [...prev, colorType]);
      }
    }
  };

  const isValidColor = (color: string): boolean => {
    const colorPattern = /^(#[0-9A-Fa-f]{3,6}|rgb\((\d{1,3},\s?){2}\d{1,3}\)|rgba\((\d{1,3},\s?){3}\d{1,3},\s?(\d(\.\d{1,2})?)\))$/;
    return colorPattern.test(color);
  };

  const saveColors = () => {
    if (invalidColors.length > 0) {
      alert("Bitte ungültige Werte bereinigen.");
      return;
    }

    themeContext.updateTheme({
      primaryMain,
      primaryLight,
      primaryDark,
      primaryDarker,
      primaryLighter,
      successMain,
      errorMain,
      backgroundDefault,
      backgroundPaper,
      textPrimary,
      textSecondary,
      grey50,
      grey100,
      grey200,
      grey400,
      grey800,
    });
  };

  return (
    <Stack>
      <Titlebar title="Einstellungen" iconName="Settings" />
      <Stack sx={{ padding: { xs: 0, sm: 2 }, }} >
        <SchochStack>
          <FormControlLabel control={<Switch checked={themeContext.isDarkTheme} onChange={themeContext.toggleTheme} />} label="Dunkelmodus (Experimentell)" />
          <Row gap={2}>
          <Stack gap={2}>
        {/* Background Colors */}
        <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Background Default"
              value={backgroundDefault}
              error={invalidColors.includes("backgroundDefault")}
              helperText={invalidColors.includes("backgroundDefault") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("backgroundDefault", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: backgroundDefault, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Background Paper"
              value={backgroundPaper}
              error={invalidColors.includes("backgroundPaper")}
              helperText={invalidColors.includes("backgroundPaper") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("backgroundPaper", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: backgroundPaper, border: 1 }}></Box>
          </Row>

          {/* Text Colors */}
          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Text Primary"
              value={textPrimary}
              error={invalidColors.includes("textPrimary")}
              helperText={invalidColors.includes("textPrimary") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("textPrimary", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: textPrimary, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Text Secondary"
              value={textSecondary}
              error={invalidColors.includes("textSecondary")}
              helperText={invalidColors.includes("textSecondary") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("textSecondary", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: textSecondary, border: 1 }}></Box>
          </Row>

          {/* Grey Colors */}
          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Grey 50"
              value={grey50}
              error={invalidColors.includes("grey50")}
              helperText={invalidColors.includes("grey50") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("grey50", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: grey50, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Grey 100"
              value={grey100}
              error={invalidColors.includes("grey100")}
              helperText={invalidColors.includes("grey100") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("grey100", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: grey100, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Grey 200"
              value={grey200}
              error={invalidColors.includes("grey200")}
              helperText={invalidColors.includes("grey200") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("grey200", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: grey200, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Grey 400"
              value={grey400}
              error={invalidColors.includes("grey400")}
              helperText={invalidColors.includes("grey400") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("grey400", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: grey400, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Grey 800"
              value={grey800}
              error={invalidColors.includes("grey800")}
              helperText={invalidColors.includes("grey800") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("grey800", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: grey800, border: 1 }}></Box>
          </Row>
        </Stack>
          <Stack gap={2}>
          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Primary Main"
              value={primaryMain}
              error={invalidColors.includes("primaryMain")}
              helperText={invalidColors.includes("primaryMain") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("primaryMain", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: primaryMain, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Primary Light"
              value={primaryLight}
              error={invalidColors.includes("primaryLight")}
              helperText={invalidColors.includes("primaryLight") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("primaryLight", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: primaryLight, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Primary Dark"
              value={primaryDark}
              error={invalidColors.includes("primaryDark")}
              helperText={invalidColors.includes("primaryDark") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("primaryDark", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: primaryDark, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Primary Darker"
              value={primaryDarker}
              error={invalidColors.includes("primaryDarker")}
              helperText={invalidColors.includes("primaryDarker") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("primaryDarker", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: primaryDarker, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Primary Lighter"
              value={primaryLighter}
              error={invalidColors.includes("primaryLighter")}
              helperText={invalidColors.includes("primaryLighter") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("primaryLighter", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: primaryLighter, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Success Main"
              value={successMain}
              error={invalidColors.includes("successMain")}
              helperText={invalidColors.includes("successMain") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("successMain", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: successMain, border: 1 }}></Box>
          </Row>

          <Row sx={{ gap: 2, alignItems: 'center' }}>
            <TextField
              size="small"
              label="Error Main"
              value={errorMain}
              error={invalidColors.includes("errorMain")}
              helperText={invalidColors.includes("errorMain") ? "Ungültig" : ""}
              onChange={(e) => handleColorChange("errorMain", e.target.value)}
            />
            <Box sx={{ width: 40, height: 40, backgroundColor: errorMain, border: 1 }}></Box>
          </Row>
          </Stack>
          </Row>
          
          
          <Button variant="contained" onClick={saveColors}>Speichern</Button>
        </SchochStack>
      </Stack>
    </Stack>
  );
};

export default SettingsPage;