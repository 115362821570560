import { Stack, TextField } from "@mui/material";
import { SchochStack } from "../../styles/styles";
import { Rapport } from "../../models/rapport";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { RapportContext } from "../Provider/RapportContextProvider";
import { ItemProps } from "../Dialogs/ItemDialog";
import RapportService from "../../services/rapport-service";

const RapportInfoItem = (props: ItemProps) => {
  const rapportContext = useContext(RapportContext);
  const [updateTrigger, setUpdateTrigger] = useState<Date>(new Date());

  const handleInputChange = (key: keyof Rapport) => (evt: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = evt.target.value;
  
    rapportContext.updateRapport({ ...rapportContext.rapport, [key]: updatedValue });
    setUpdateTrigger(new Date());
  };

  useEffect(() => {
    const filled = RapportService.isRapportInfoFilled(rapportContext.rapport);
    props.updateIsFilled(filled);
  }, [updateTrigger]);

  return (
    <Stack gap={2} marginTop={2}>
    <SchochStack>
      <TextField
        label="Auftrag erteilt durch"
        value={rapportContext.rapport.reporter}
        onChange={handleInputChange('reporter')}
      />
      <TextField
        label="Telefonische Rückfragen"
        value={rapportContext.rapport.phoneNr}
        onChange={handleInputChange('phoneNr')}
      />
      <TextField
        label="Kostenstelle"
        value={rapportContext.rapport.cost}
        onChange={handleInputChange('cost')}
      />
      <TextField
        label="Zähler-Nr."
        value={rapportContext.rapport.counter}
        onChange={handleInputChange('counter')}
      />
      <TextField
        label="Weitere Informationen"
        value={rapportContext.rapport.more}
        onChange={handleInputChange('more')}
        rows={4}
        multiline
      />
    </SchochStack>
  </Stack>
  );
};

export default RapportInfoItem;