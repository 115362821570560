import { Page, Text, View, Document, StyleSheet, Path, Svg, Font, Image } from '@react-pdf/renderer';
import JobHelper from '../helpers/job-helper';
import RapportService from '../services/rapport-service';
import { Signature } from '../models/signature';
import { Rapport } from '../models/rapport';
import { PriceTotal } from '../models/price-total';

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/roboto/v15/W5F8_SL0XFawnjxHGsZjJA.ttf", // Regular
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v15/bdHGHleUa-ndQCOrdpfxfw.ttf", // Bold
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    padding: 50,
    paddingBottom: 140,
    gap: 16,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#BBBBBB',
    padding: 8,
    borderBottom: 2,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    backgroundColor: '#FFFFFF',
    borderBottom: 1,
    borderColor: '#DDDDDD',
  },
  tableRowTotal: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    backgroundColor: '#FFFFFF',
    borderBottom: 1,
    borderTop: 1,
    borderColor: '#DDDDDD',
  },
  tableRowEven: {
    backgroundColor: '#EEEEEE'
  },
  tableRowLast: {
    border: 0,
  },
  tableCell: {
    flex: 1,
    textAlign: 'center',
  },
  tableCellLarge: {
    flex: 3,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    width: '100%',
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 8,
    gap: 2,
    border: 1,
    borderColor: '#DDDDDD',
  },
  divider: {
    border: 1,
    borderColor: '#DDDDDD',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: 32,
    fontWeight: 'bold',
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    paddingBottom: 8,
  },
  textSmall: {
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  textMedium: {
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  gap4: {
    gap: 4,
  },
  gap8: {
    gap: 8,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  pageNumber: {
    position: 'relative',
    top: 105,
    left: 290,
    zIndex: 0,
  },
  svg: {
    position: 'relative',
    top: 0,
    left: 0,
    zIndex: -1,
  },
  empty: {},
});

type PreviewDocumentProps = {
  rapport: Rapport;
  priceTotal: PriceTotal;
  signature: Signature | null;
};

const PreviewDocument = (props: PreviewDocumentProps) => {
  return (
    <Document>
      <Page style={styles.body} size="A4">

        {/* Header */}
        <View style={styles.rowContainer} fixed>
          <Text style={styles.title}>Service-Rapport</Text>
          <View style={styles.headerTextContainer}>
            <Text style={styles.textMedium}>{props.rapport.dueDate?.toLocaleDateString('de-DE', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              }) ?? "(Kein Datum)"}
            </Text>
            <Text style={styles.textMedium}>{props.rapport.emilId}{props.rapport.projectId.trim() !== "" ? ` (${props.rapport.projectId})` : ""}</Text>
          </View>
        </View>

        {/* Beschreibung */}
        {(props.rapport.betreff.trim() !== "" && props.rapport.description.trim() !== "") && <View>
          <Text style={styles.subtitle}>{props.rapport.betreff}</Text>
          <Text style={[styles.textMedium, { lineHeight: 1.5 }]}>{props.rapport.description}</Text>
        </View>}

        {/* Adressen */}
        <View style={styles.row} wrap={false}>
          {props.rapport.customers.slice(0, 3).map((customer, index) => (
            <View key={index} style={styles.stack}>
              <Text style={[styles.subtitle, { textAlign: 'center' }]}>
                {index === 0 ? 'Kunde' : index === 1 ? 'Ausführungsort' : 'Anschrift'}
              </Text>
              {RapportService.isAddressFilled(customer) ? <View>
                <Text style={styles.textSmall}>
                  {customer.firstname} {customer.lastname}
                </Text>
                <Text style={styles.textSmall}>{customer.street}</Text>
                <Text style={styles.textSmall}>
                  {customer.postalCode} {customer.city}
                </Text>
                {!!customer.mail && (
                  <Text style={styles.textSmall}>
                    {customer.mail}
                  </Text>
                )}
                {!!customer.phone && (
                  <Text style={styles.textSmall}>
                    {customer.phone}
                  </Text>
                )}
              </View> : <Text style={[styles.textSmall, {alignSelf: 'center'}]}>(Keine Adresse)</Text>}
            </View>
          ))}
        </View>

        {/* Arbeitszeit */}
        {props.rapport.worktimes.length > 0 && <View>
          <View style={styles.rowContainer}>
            <Text style={styles.subtitle}>Arbeitszeit</Text>
            {!props.rapport.showPrices && <Text style={styles.textSmall}>Preise werden nicht angezeigt</Text>}
          </View>
          <View style={styles.tableHeader} fixed>
            <Text style={[styles.tableCellLarge, styles.textMedium]}>Name</Text>
            <Text style={[styles.tableCell, styles.textMedium]}>Artikel-Nr.</Text>
            <Text style={[styles.tableCell, styles.textMedium]}>Arbeitszeit</Text>
            <Text style={[styles.tableCell, styles.textMedium, { textAlign: 'right' }]}>Ansatz</Text>
            <Text style={[styles.tableCell, styles.textMedium, { textAlign: 'right' }]}>Betrag</Text>
          </View>
          {props.rapport.worktimes.map((worktime, index) => (
            <View key={index} style={[styles.tableRow, (index % 2 === 1) ? styles.tableRowEven : styles.empty]} wrap={false}>
              <Text style={[styles.tableCellLarge, styles.textSmall]}>{worktime.username.trim() !== "" ? worktime.username : "-"}</Text>
              <Text style={[styles.tableCell, styles.textSmall]}>{worktime.productId.trim() !== "" ? worktime.productId : "-"}</Text>
              <Text style={[styles.tableCell, styles.textSmall]}>{Number(worktime.timeInHours).toFixed(2)}h</Text>
              <Text style={[styles.tableCell, styles.textSmall, { textAlign: 'right' }]}>{props.rapport.showPrices ? `${Number(worktime.price).toFixed(2)} CHF` : "-"}</Text>
              <Text style={[styles.tableCell, styles.textSmall, { textAlign: 'right' }]}>{props.rapport.showPrices ? `${(Number(worktime.price) * Number(worktime.timeInHours)).toFixed(2)} CHF` : "-"}</Text>
            </View>
          ))}
          {props.rapport.showPrices && <View style={styles.tableRowTotal}>
            <Text style={[styles.tableCell, styles.textMedium, { textAlign: 'left' }]}>Total</Text>
            <Text style={[styles.tableCell, styles.textMedium, { textAlign: 'right' }]}>{(props.rapport.worktimes.reduce((sum, worktime) => sum + Number(worktime.price) * Number(worktime.timeInHours), 0)).toFixed(2)} CHF</Text>
          </View>}
        </View>}

        {/* Material */}
        {props.rapport.materials.length > 0 && <View>
          <View style={styles.rowContainer}>
            <Text style={styles.subtitle} fixed>Material</Text>
            {!props.rapport.showPrices && <Text style={styles.textSmall}>Preise werden nicht angezeigt</Text>}
          </View>
          <View style={styles.tableHeader} fixed>
            <Text style={[styles.tableCellLarge, styles.textMedium]}>Material</Text>
            <Text style={[styles.tableCell, styles.textMedium]}>Artikel-Nr.</Text>
            <Text style={[styles.tableCell, styles.textMedium]}>Menge</Text>
            <Text style={[styles.tableCell, styles.textMedium, { textAlign: 'right' }]}>Stückpreis</Text>
            <Text style={[styles.tableCell, styles.textMedium, { textAlign: 'right' }]}>Betrag</Text>
          </View>
          {props.rapport.materials.map((material, index) => (
            <View key={index} style={[styles.tableRow, (index % 2 === 1) ? styles.tableRowEven : styles.empty]} wrap={false}>
              <Text style={[styles.tableCellLarge, styles.textSmall]}>{material.name.trim() !== "" ? material.name : "-"}</Text>
              <Text style={[styles.tableCell, styles.textSmall]}>{material.displayedProductId.trim() !== "" ? material.displayedProductId : "-"}</Text>
              <Text style={[styles.tableCell, styles.textSmall]}>{material.amount}</Text>
              <Text style={[styles.tableCell, styles.textSmall, { textAlign: 'right' }]}>{props.rapport.showPrices ? `${Number(material.price).toFixed(2)} CHF` : "-"}</Text>
              <Text style={[styles.tableCell, styles.textSmall, { textAlign: 'right' }]}>{props.rapport.showPrices ? `${(Number(material.price) * Number(material.amount)).toFixed(2)} CHF` : "-"}</Text>
            </View>
          ))}
          {props.rapport.showPrices && <View style={styles.tableRowTotal}>
            <Text style={[styles.tableCell, styles.textMedium, { textAlign: 'left' }]}>Total</Text>
            <Text style={[styles.tableCell, styles.textMedium, { textAlign: 'right' }]}>{(props.rapport.materials.reduce((sum, material) => sum + Number(material.price) * Number(material.amount), 0)).toFixed(2)} CHF</Text>
          </View>}
        </View>}

        {/* Gesamtbetrag */}
        {props.rapport.showPrices && <View wrap={false}>
          <Text style={styles.subtitle}>Gesamtbetrag</Text>
        <View style={styles.gap8}>
          <View style={styles.rowContainer}>
            <Text style={styles.textMedium}>Arbeitszeit</Text>
            <Text style={styles.textMedium}>{`${props.priceTotal.worktimeTotal.toFixed(2)} CHF`}</Text>
          </View>
          <View style={styles.rowContainer}>
            <Text style={styles.textMedium}>Material</Text>
            <Text style={styles.textMedium}>{`${props.priceTotal.materialTotal.toFixed(2)} CHF`}</Text>
          </View>
          <View style={styles.divider}></View>
          {props.rapport.discount > 0 && <View style={styles.rowContainer}>
            <Text style={styles.textMedium}>{`Rabatt (${props.rapport.discount}%)`}</Text>
            <Text style={styles.textMedium}>-{`${props.priceTotal.discountAmount.toFixed(2)} CHF`}</Text>
          </View>}
          <View style={styles.rowContainer}>
            <Text style={styles.textMedium}>Brutto</Text>
            <Text style={styles.textMedium}>{`${props.priceTotal.netTotal.toFixed(2)} CHF`}</Text>
          </View>
          <View style={styles.rowContainer}>
            <Text style={styles.textMedium}>{`MWST (${props.rapport.vat}%)`}</Text>
            <Text style={styles.textMedium}>{`${props.priceTotal.vatAmount.toFixed(2)} CHF`}</Text>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.rowContainer}>
            <Text style={[styles.textMedium, { fontWeight: 'bold'}]}>Netto</Text>
            <Text style={[styles.textMedium, { fontWeight: 'bold'}]}>{`${props.priceTotal.total.toFixed(2)} CHF`}</Text>
          </View>
        </View>
      </View>}

        {/* Auftragsdaten */}
        {RapportService.isRapportInfoFilled(props.rapport) && <View wrap={false}>
          <Text style={styles.subtitle}>Auftragsdaten</Text>
          <View style={styles.gap4}>
            {props.rapport.createDate !== null && <Text style={styles.textMedium}><Text style={{ fontWeight: 'bold', }}>Auftrag erteilt am:</Text> {props.rapport.createDate.toLocaleDateString('de-DE', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}</Text>}
            {props.rapport.reporter.trim() !== "" && <Text style={styles.textMedium}><Text style={{ fontWeight: 'bold', }}>Auftrag erteilt durch: </Text>{props.rapport.reporter}</Text>}
            {props.rapport.creator !== null && <Text style={styles.textMedium}><Text style={{ fontWeight: 'bold', }}>Auftrag erfasst durch: </Text>{props.rapport.creator.username}</Text>}
            {props.rapport.phoneNr.trim() !== "" && <Text style={styles.textMedium}><Text style={{ fontWeight: 'bold', }}>Telefon-Nr.: </Text>{props.rapport.phoneNr}</Text>}
            {props.rapport.cost.trim() !== "" && <Text style={styles.textMedium}><Text style={{ fontWeight: 'bold', }}>Kostenstelle: </Text>{props.rapport.cost}</Text>}
            {props.rapport.counter.trim() !== "" && <Text style={styles.textMedium}><Text style={{ fontWeight: 'bold', }}>Zähler-Nr: </Text>{props.rapport.counter}</Text>}
            {props.rapport.more.trim() !== "" && <Text style={styles.textMedium}><Text style={{ fontWeight: 'bold', }}>Weitere Informationen: </Text>{props.rapport.more}</Text>}
          </View>
        </View>}

        {/* Tätigkeiten */}
        {RapportService.isJobFilled(props.rapport) && <View wrap={false}>
          <Text style={styles.subtitle}>Tätigkeiten</Text>
          <View>
            <Text style={styles.textMedium}>
              {JobHelper.getJobLabels()
                .filter((label) => JobHelper.getKeyFromLabel(label) && props.rapport.jobs[JobHelper.getKeyFromLabel(label)])
                .join(', ')}
            </Text>
          </View>
        </View>}

        {/* Messungen */}
        {RapportService.isMeasurementFilled(props.rapport) && <View wrap={false}>
          <Text style={styles.subtitle}>Messungen</Text>
          <View style={styles.gap4}>
            {[
              { label: "FI Auslösestärke", value: props.rapport.measurements.fi },
              { label: "Sichtkontrolle nach NIN", value: props.rapport.measurements.nin },
              { label: "TN-S", value: props.rapport.measurements.tnS },
              { label: "TN-C", value: props.rapport.measurements.tnC },
              { label: "TN-C-S", value: props.rapport.measurements.tnCS },
              { label: "Zähler-Nr.", value: props.rapport.measurements.counter },
              { label: "In (A), PE geprüft", value: props.rapport.measurements.inA },
              { label: "R iso", value: props.rapport.measurements.rIso },
              { label: "IK (A)", value: props.rapport.measurements.ikA },
              { label: "M Ohm", value: props.rapport.measurements.mOhm },
            ].map((field, index) => (
              field.value?.trim() !== "" && (
                <Text key={index} style={styles.textMedium}>
                  <Text style={{ fontWeight: 'bold', }}>{field.label}: </Text>
                  {field.value}
                </Text>
              )
            ))}
          </View>
        </View>}

        {/* Unterschrift */}
        {props.signature !== null && <View wrap={false}>
          <Text style={styles.subtitle}>Unterschrift</Text>
          {props.signature !== null && <Image
            src={`data:image/png;base64,${props.signature.content}`}
            style={{ height: 100 }}
          />}
        </View>}


        {/* Footer */}
        <View style={styles.footer} fixed>
          <Text style={[styles.textMedium, styles.pageNumber]} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} />
          <Svg style={styles.svg} viewBox="0 0 1440 320">
            <Path
              d="M0,96L48,90.7C96,85,192,75,288,80C384,85,480,107,576,144C672,181,768,235,864,245.3C960,256,1056,224,1152,202.7C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              fill="rgba(255, 224, 178, 1)"
              opacity="1"
            />
            <Path
              d="M0,320L48,282.7C96,245,192,171,288,122.7C384,75,480,53,576,64C672,75,768,117,864,128C960,139,1056,117,1152,128C1248,139,1344,181,1392,202.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              fill="rgba(255, 204, 128, 1)"
              opacity="1"
            />
            <Path
              d="M0,256L48,250.7C96,245,192,235,288,245.3C384,256,480,288,576,277.3C672,267,768,213,864,165.3C960,117,1056,75,1152,64C1248,53,1344,75,1392,85.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              fill="rgba(255, 183, 77, 1)"
              opacity="1"
            />
          </Svg>
        </View>
      </Page>
    </Document>
  );
};

export default PreviewDocument;
