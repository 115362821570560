import { CreateUserDto } from "../dto/users/create-user-dto";
import { ResetPasswordDto } from "../dto/users/reset-password-dto";
import { UpdateUserDto } from "../dto/users/update-user-dto";
import { UserDto } from "../dto/users/user-dto";
import { ResetPassword } from "../models/reset-password";
import { User } from "../models/user";
import { base64ToBlob } from "./utils";

const UserHelper = {
  userToUpdate: (user: User): UpdateUserDto => {
    return {
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
    };
  },

  userToCreate: (user: User): CreateUserDto => {
    return {
      username: user.username,
      password: user.password,
      firstName: user.firstName,
      lastName: user.lastName,
    };
  },

  userToDto: (user: User): UserDto => {
    return {
      id: user.id,
      profilePicture: null,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      color: user.color,
      isAdmin: user.isAdmin,
      workload: user.workload,
      hourlyRate: user.hourlyRate,
      productNr: user.productNr,
    }
  },

  userFromDto: (userDto: UserDto): User => {
    return {
      id: userDto.id,
      profilePicture: userDto.profilePicture ? base64ToBlob(userDto.profilePicture, 'image/*') : null,
      username: userDto.username,
      password: '',
      confirmPassword: '',
      firstName: userDto.firstName,
      lastName: userDto.lastName,
      color: userDto.color,
      isAdmin: userDto.isAdmin,
      workload: userDto.workload,
      hourlyRate: userDto.hourlyRate,
      productNr: userDto.productNr,
    };
  },

  resetPasswordToDto: (resetPassword: ResetPassword): ResetPasswordDto => {
    return {
      firmLicence: resetPassword.firmLicence,
      userLicence: resetPassword.userLicence,
      password: resetPassword.password,
    };
  },
}

export default UserHelper;