import { Stack, Box, Typography, FormControlLabel, Switch, List, ListItemButton, Avatar, Divider, ListItem, IconButton, Input, Chip, Button } from "@mui/material";
import DynamicIcon from "./DynamicIcon";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ChangeEvent, useContext, useEffect, useState } from "react";
import RapportItemHelper from "../helpers/rapport-item-helper";
import ItemEntry from "../models/item-entry";
import { RapportContext } from "./Provider/RapportContextProvider";
import { roundCHF } from "../helpers/utils";

const EditTotal = () => {
  const rapportContext = useContext(RapportContext);
  const [addDiscount, setAddDiscount] = useState(false);
  const [editDiscount, setEditDiscount] = useState(false);
  const [editMWST, setEditMWST] = useState(false);

  const [materialTotal, setMaterialTotal] = useState(0);
  const [worktimeTotal, setWorktimeTotal] = useState(0);
  const [netto, setNetto] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [MWSTAmount, setMWSTAmount] = useState(0);
  const [total, setTotal] = useState(0);

  const worktimeItemEntry: ItemEntry = RapportItemHelper.getItemByBeName('worktime')!;
  const materialItemEntry: ItemEntry = RapportItemHelper.getItemByBeName('material')!;

  const updateDiscount = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(evt.target.value);
    rapportContext.updateRapport({...rapportContext.rapport, discount: isNaN(newValue) ? 0 : newValue});
  };

  const updateMWST = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(evt.target.value);
    rapportContext.updateRapport({...rapportContext.rapport, vat: isNaN(newValue) ? 0 : newValue});
  };

  const recalculate = () => {
    let mTotal = 0;
    rapportContext.rapport.materials.forEach(material => {
      const amount = Number(material.amount) ?? 0;
      const price = Number(material.price) ?? 0;
      mTotal += roundCHF(amount * price);
    });

    let wTotal = 0;
    rapportContext.rapport.worktimes.forEach(worktime => {
      const time = Number(worktime.timeInHours);
      const price = Number(worktime.price);
      wTotal += roundCHF(time * price);
    });

    const mwTotal = mTotal + wTotal;
    const dAmount = roundCHF(mwTotal * (rapportContext.rapport.discount / 100));
    const net = mwTotal - dAmount;
    const mwstAmount = roundCHF(net * (rapportContext.rapport.vat / 100));

    setMaterialTotal(mTotal);
    setWorktimeTotal(wTotal);
    setNetto(net);
    setDiscountAmount(dAmount);
    setMWSTAmount(mwstAmount);
    setTotal(net + mwstAmount);
  };

  useEffect(() => {
    if (rapportContext.rapport.discount !== 0) {
      setAddDiscount(true);
    }

    recalculate();
  }, [rapportContext.rapport]);

  return (
    <Stack>
      <Box gap={2} display='flex' justifyContent='space-evenly' alignItems='center'>
        <DynamicIcon iconName="LocalOffer" fontSize="large" />
        <Typography sx={{ flex: 1, textAlign: 'center'}} variant='h6'>Gesamtbetrag</Typography>
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
        <FormControlLabel control={<Switch onChange={() => rapportContext.updateRapport({...rapportContext.rapport, showPrices: !rapportContext.rapport.showPrices})} checked={rapportContext.rapport.showPrices} />} label="Preise anzeigen" />
      </Box>
      <List>
        <ListItemButton
          onClick={() => rapportContext.setSelectedItem(worktimeItemEntry)}
        >
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, }}>
              <Avatar
                sx={{ backgroundColor: theme => theme.palette.primary.main }}
                variant='rounded'
              >
                <DynamicIcon iconName={worktimeItemEntry.iconName} />
              </Avatar>
              <Typography>{worktimeItemEntry.name}</Typography>
            </Box>
            <Box sx={{ display: rapportContext.rapport.showPrices ? 'flex' : 'none', flexDirection: 'row', gap: 2 }}>
              <Typography>{worktimeTotal.toFixed(2)}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
        </ListItemButton>
        <ListItemButton
          onClick={() => rapportContext.setSelectedItem(materialItemEntry)}
        >
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, }}>
              <Avatar
                sx={{ backgroundColor: theme => theme.palette.primary.main }}
                variant='rounded'
              >
                <DynamicIcon iconName={materialItemEntry.iconName} />
              </Avatar>
              <Typography>{materialItemEntry.name}</Typography>
            </Box>
            <Box sx={{ display: rapportContext.rapport.showPrices ? 'flex' : 'none', flexDirection: 'row', gap: 2 }}>
              <Typography>{materialTotal.toFixed(2)}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
        </ListItemButton>
        <Divider />
      </List>
      <List sx={{ display: rapportContext.rapport.showPrices ? 'block' : 'none'}}>
      {addDiscount && (<ListItem>
          <IconButton sx={{ padding: 0, paddingRight: 1}} color="error" onClick={() => {setAddDiscount(false); rapportContext.updateRapport({...rapportContext.rapport, discount: 0})}}>
              <HighlightOffIcon fontSize="small" />
          </IconButton>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', margin: 0, gap: 2, alignItems: 'center' }}>
              <Typography>Rabatt</Typography>
              {editDiscount ? (
              <Box>
                <Input
                  autoFocus
                  onChange={updateDiscount}
                  type="numeric"
                  size="small"
                  sx={{ width: 40 }} />%
                  <IconButton onClick={() => setEditDiscount(false)}>
                    <CheckIcon fontSize="small" />
                  </IconButton>
              </Box>
              ) : (
              <Chip
                icon={<EditIcon />}
                onClick={() => setEditDiscount(true)}
                label={rapportContext.rapport.discount + '%'}
                size="small"
              />
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>-{discountAmount.toFixed(2)}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
        </ListItem>)}
        <ListItem>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Typography>Brutto</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>{netto.toFixed(2)}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
        </ListItem>
        <ListItem>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>MWST</Typography>
              {editMWST ? (
              <Box>
                <Input
                  autoFocus
                  onChange={updateMWST}
                  type="numeric"
                  size="small"
                  sx={{ width: 40 }} />%
                  <IconButton onClick={() => setEditMWST(false)}>
                    <CheckIcon fontSize="small" />
                  </IconButton>
              </Box>
              ) : (
              <Chip
                icon={<EditIcon />}
                onClick={() => setEditMWST(true)}
                label={rapportContext.rapport.vat + '%'}
                size="small"
              />
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>{MWSTAmount.toFixed(2)}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
        </ListItem>
        <Divider />
        <ListItem>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Typography fontWeight='bold'>Netto</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography fontWeight='bold'>{total.toFixed(2)}</Typography>
              <Typography fontWeight='bold'>CHF</Typography>
            </Box>
          </Box>
        </ListItem>
      </List>
      {!addDiscount && <Button sx={{ display: rapportContext.rapport.showPrices ? 'block' : 'none', padding: 0}} onClick={() => setAddDiscount(true)}>Rabatt hinzufügen</Button>}
    </Stack>
  );
};

export default EditTotal;