import { Box, Stack, Typography, Link, Tooltip } from "@mui/material";
import { Rapport } from "../models/rapport";
import RapportService from "../services/rapport-service";
import GoogleMapsLink from "./GoogleMapsLink";
import { useEffect, useState } from "react";

type RapportViewAddressProps = {
  rapport: Rapport;
};

const RapportViewAddress = (props: RapportViewAddressProps) => {
  const [mapsLinks, setMapsLinks] = useState<string[]>([]);

  useEffect(() => {
    if (props.rapport.customers?.length > 0) {
      const links = props.rapport.customers.map(customer =>
        `${customer.street} ${customer.postalCode} ${customer.city}`.trim()
      );
      setMapsLinks(links);
    } else {
      setMapsLinks([]);
    }
  }, [props.rapport.customers]);
  
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
      {props.rapport.customers.slice(0, 3).map((customer, index) => (
        <Stack
          key={index}
          sx={{
            position: 'relative',
            flex: 1,
            border: 1,
            padding: 2,
            width: { sm: 0 },
          }}
        >
          <Typography sx={{ textAlign: 'center' }} variant="h6">
            {index === 0 ? "Kunde" : index === 1 ? "Ausführungsort" : "Anschrift"}
          </Typography>
          {RapportService.isAddressFilled(customer) ? <Stack>
            <Tooltip title={`${customer.firstname} ${customer.lastname}`}>
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {customer.firstname} {customer.lastname}
              </Typography>
            </Tooltip>
            <Tooltip title={customer.street}>
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {customer.street}
              </Typography>
            </Tooltip>
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {customer.postalCode} {customer.city}
            </Typography>
              {!!customer.mail && (
                <Link href={`mailto:${customer.mail}`} color="inherit">
                  <Typography>{customer.mail}</Typography>
                </Link>
              )}
              {!!customer.phone && (
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                <Link href={`tel:${customer.phone}`} color="inherit">
                  {customer.phone}
                </Link>
              </Typography>)}
            </Stack> : <Typography sx={{ fontStyle: "italic", alignSelf: 'center', padding: 1 }} variant="subtitle1">(Keine Adresse)</Typography>}
          {!!(mapsLinks.length > 2 && mapsLinks[index] !== "") && (
            <Box sx={{ position: 'absolute', bottom: 5, right: 5 }}>
              <GoogleMapsLink address={mapsLinks[index]} />
            </Box>
          )}
        </Stack>
      ))}
    </Box>
  );
};

export default RapportViewAddress;