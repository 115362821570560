import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Autocomplete, Box, Button, Checkbox, Divider, Fab, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import RapportEntryList from "../components/RapportEntryList";
import SaveIcon from '@mui/icons-material/Save';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import { RapportContext } from "../components/Provider/RapportContextProvider";
import RapportHelper from "../helpers/rapport-helper";
import EditCustomer from "./EditCustomer";
import { MetaContext } from "./Provider/MetaContextProvider";
import { User } from "../models/user";
import { MeContext } from "./Provider/MeContextProvider";
import { SchochStack } from "../styles/styles";
import CommentDialog from "./Dialogs/CommentDialog";
import ItemDialog from "./Dialogs/ItemDialog";
import EditTotal from "./EditTotal";
import DynamicIcon from "./DynamicIcon";
import Prompt from "./Prompt";

const EditRapport = () => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const rapportContext = useContext(RapportContext);
  const [openComment, setOpenComment] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const updateBetreff = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    rapportContext.updateRapport({...rapportContext.rapport, betreff: newValue});
  };

  const updateProjectId = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    rapportContext.updateRapport({...rapportContext.rapport, projectId: newValue});
  };

  const updateTime = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    rapportContext.updateRapport({...rapportContext.rapport, dueDateTime: newValue});
  };

  const updateDate = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    rapportContext.updateRapport({...rapportContext.rapport, dueDateDate: newValue});
  };

  const updateJobDone = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.checked;
    rapportContext.updateRapport({...rapportContext.rapport, jobDone: newValue});
  };

  const updateBilled = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.checked;
    rapportContext.updateRapport({...rapportContext.rapport, billed: newValue});
  };

  const updateStatus = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    rapportContext.updateRapport({...rapportContext.rapport, status: newValue});
  };

  const updateDescription = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    rapportContext.updateRapport({...rapportContext.rapport, description: newValue});
  };

  const updateAssigneeId = (newValue: User | null) => {
    rapportContext.updateRapport({...rapportContext.rapport, assigneeId: newValue?.id ?? null});
  };

  const handleSaveButtonClick = () => {
    if (!!meContext.currentFirm) {
      rapportContext.saveRapport();
    } else {
      context.setAlertMessage("Treten Sie einer Firma bei, um Rapporte speichern zu können.");
      context.setAlertSeverity("error");
    }
  };

  const setToNow = () => {
    const currentDate = new Date();
    const currentHours = String(currentDate.getHours()).padStart(2, '0');
    const currentMinutes = String(currentDate.getMinutes()).padStart(2, '0');
    const currentTime = `${currentHours}:${currentMinutes}`;
    const formattedDate = currentDate.toLocaleDateString('sv-SE');
    rapportContext.updateRapport({...rapportContext.rapport, dueDateDate: formattedDate, dueDateTime: currentTime});
  };

  const assignToMe = () => {
    if (meContext.currentUser === null) {
      return;
    }

    rapportContext.updateRapport({...rapportContext.rapport, assigneeId: meContext.currentUser!.id});
  };

  const filteredUsers = (meContext.firmUsers === null) ? [] : meContext.firmUsers.filter((user) => user.username.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Stack sx={{ padding: { sm: 2 }, gap: 2 }}>
      <SchochStack sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
        <Stack sx={{ flex: 1, gap: 2, }}>
          <TextField
            value={rapportContext.rapport.betreff}
            type="text"
            label="Betreff"
            size="small"
            error={rapportContext.isWarning && !rapportContext.rapport.betreff}
            onChange={updateBetreff}
          />
          <Box display='flex' flexDirection="row" gap={1}>
            <Autocomplete
              key={rapportContext.rapport.assigneeId}
              size="small"
              sx={{ flex: 1 }}
              noOptionsText='Keine Treffer'
              options={filteredUsers}
              getOptionLabel={(user) => user.username}
              value={filteredUsers.find((user) => user.id === rapportContext.rapport.assigneeId) || null}
              renderInput={(params) => <TextField {...params} variant="outlined" label='Beauftragt' onChange={(e) => setSearchTerm(e.target.value)} />}
              onChange={(_, newValue) => updateAssigneeId(newValue)}
            />
            <Button onClick={assignToMe}>Mir zuweisen</Button>
          </Box>
        </Stack>
        <Stack sx={{ width: { sm: '20%' }, gap: 2, flexDirection: { xs: 'row', sm: 'column'},  }}>
          <TextField
            sx={{ width: { xs: '50%', sm: 'auto' } }}
            value={rapportContext.rapport.projectId}
            label='Projekt-Nr.'
            type="text"
            size="small"
            onChange={updateProjectId}
          />
          <TextField
            sx={{ width: { xs: '50%', sm: 'auto' } }}
            value={rapportContext.rapport.status}
            label='Status'
            type="text"
            size="small"
            onChange={updateStatus}
          />
        </Stack>
        <Stack sx={{ justifyContent: 'center', gap: 2, flexDirection: { xs: 'row', sm: 'column'} }}>
          <FormControlLabel
            control={<Checkbox checked={rapportContext.rapport.jobDone} onChange={updateJobDone} />}
            label="Arbeit erledigt"
          />
          <FormControlLabel
            control={<Checkbox checked={rapportContext.rapport.billed} onChange={updateBilled} />}
            label="Verrechnet"
          />
        </Stack>
      </SchochStack>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } , gap: 2, }} >
        <SchochStack>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <AccessTimeIcon fontSize='large' />
            <Typography sx={{flex: 1, textAlign: 'center'}} variant='h6'>Termin</Typography>
          </Box>
          <Button onClick={setToNow}>Auf 'Jetzt' stellen</Button>
          <Box gap={1} flex={1} display="flex" flexDirection="row">
            <TextField
              sx={{ width: '50%' }}
              value={rapportContext.rapport.dueDateDate}
              type='date'
              onChange={updateDate}
              size='small'
              error={rapportContext.isWarning && !RapportHelper.isValidDate(rapportContext.rapport.dueDateDate)}
              helperText={(rapportContext.isWarning && !RapportHelper.isValidDate(rapportContext.rapport.dueDateDate)) && "Jahr von 1970-9999"}
            />
            <TextField
              sx={{ flex: 1 }}
              value={rapportContext.rapport.dueDateTime}
              type='time'
              onChange={updateTime}
              size='small'
            />
          </Box>
        </SchochStack>
        <SchochStack>
          <Box display='flex' justifyContent='space-evenly' alignItems='center'>
            <DescriptionIcon fontSize="large" />
            <Typography sx={{ flex: 1, textAlign: 'center'}} variant='h6'>Beschreibung</Typography>
          </Box>
          <TextField
            multiline
            rows={3}
            value={rapportContext.rapport.description}
            type='text'
            label= "Beschreibung"
            onChange={updateDescription}
            fullWidth
          />
        </SchochStack>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } , gap: 2, }} >
        <SchochStack sx={{ paddingBottom: (theme) => theme.spacing(0)}}>
          <EditCustomer />
        </SchochStack>
        <SchochStack>
          <EditTotal />
        </SchochStack>
      </Box>
      <Divider />
      <Stack>
        <RapportEntryList entries={rapportContext.rapport.entries} isEditable={true} />
      </Stack>
      {!!rapportContext.rapportId && <Fab
        color={rapportContext.rapport.comments.length > 0 ? "primary" : "inherit"}
        size="small"
        sx={{
          position: 'fixed',
          bottom: (theme) => theme.spacing(20),
          right: (theme) => theme.spacing(4),
        }}
        onClick={() => setOpenComment(true)}
      >
        <DynamicIcon iconName="Comment" />
      </Fab>}
      <Fab
        color={ !!meContext.currentFirm ? "primary" : "error" }
        sx={{
          position: 'fixed',
          bottom: (theme) => theme.spacing(10),
          right: (theme) => theme.spacing(3),
        }}
        onClick={handleSaveButtonClick}
      >
        <SaveIcon />
      </Fab>
      <CommentDialog isOpen={openComment} onClose={() => setOpenComment(false)} />
      <ItemDialog />
      <Prompt
        when={rapportContext.changesMade} 
        message="Ungespeicherte Änderungen werden verworfen. Wollen Sie fortsetzen?" 
        beforeUnload={true} 
      />
    </Stack> 
  );
};

export default EditRapport;