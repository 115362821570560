import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import Titlebar from "../components/Titlebar";
import { Rapport, defaultRapport } from "../models/rapport";
import RapportService from "../services/rapport-service";
import { MetaContext } from "../components/Provider/MetaContextProvider";
import { pdf } from "@react-pdf/renderer";
import DynamicIcon from "../components/DynamicIcon";
import AttachmentService from "../services/attachment-service";
import { SchochStack } from "../styles/styles";
import { Signature } from "../models/signature";
import PreviewDocument from "../components/PDFDocument";
import SchochButton from "../components/Buttons/SchochButton";
import RapportView from "../components/RapportView";
import { defaultPriceTotal, PriceTotal } from "../models/price-total";

const RapportPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const context = useContext(MetaContext);
  const [rapport, setRapport] = useState<Rapport>(defaultRapport);
  const [signature, setSignature] = useState<Signature | null>(null);
  const [total, setTotal] = useState<PriceTotal>(defaultPriceTotal);

  const fetchRapport = async () => {
    if (!id) {
      return;
    }

    const data = await RapportService.getRapport(id);
    if (!!data) {
      setRapport(data);
    } else {
      context.setAlertMessage("Daten konnten nicht geladen werden.");
      context.setAlertSeverity("warning");
    }
  };

  const fetchSignature = async () => {
    if (!!!rapport.id || signature !== null) {
      return;
    }

    const data = await context.handleAsyncOperation(
      () => AttachmentService.getSignature(rapport.id)
    );

    setSignature(data);
  };

  const fetchPriceTotal = async () => {
    if (!!!rapport.id || signature !== null) {
      return;
    }

    const data = await context.handleAsyncOperation(
      () => RapportService.calculateRapportTotal(rapport.id)
    );

    setTotal(data);
  };

  const handleOpenPdf = async () => {
    const blob = await pdf(<PreviewDocument rapport={rapport} priceTotal={total} signature={signature} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };

  useEffect(() => {
    fetchRapport();
  }, []);

  useEffect(() => {
    fetchSignature();
    fetchPriceTotal();
  }, [rapport]);

  return (
    <Stack>
      <Titlebar title="Rapport" iconName="" />
      <Stack sx={{ gap: 2, padding: { xs: 1, sm: 2 }, paddingTop: 0 }}>
        <SchochStack sx={{ flexDirection: 'row', gap: 2 }}>
          <SchochButton icon={<DynamicIcon iconName="InsertDriveFile" />} text="PDF öffnen" onClick={handleOpenPdf} />
          <SchochButton icon={<DynamicIcon iconName="Edit" />} text="Bearbeiten" onClick={() => navigate(`/rapport/${rapport.id}/edit`)} />
        </SchochStack>
        <RapportView rapport={rapport} priceTotal={total} signature={signature} />
      </Stack>
    </Stack>
  );
};

export default RapportPage;